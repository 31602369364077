import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  Divider,
  Affix,
  InputNumber,
} from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { IBookLandingPage } from "../../../utils/interfaces/BookLandingPage";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { getAllAuthors, getAllProducts } from "../../../services/dashboard";
import ImageUploadUrl from "../../shared/ImageUploadUrl";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useNavigate, useParams } from "react-router-dom";
import {
  createLandingPage,
  getSingleLandingPage,
  updateLandingPage,
} from "../../../services/landingPage";
import { notify } from "../../shared/basic/notify";
import extractErrorMessage from "../../shared/basic/formateError";
import { generateUrlForImage } from "../../ProductsComp/Books";
import { Endpoints } from "../../../network";
import CustomLoader from "../../shared/Loading";

type FilteredDataType = Partial<IBookLandingPage>;

const initialFormData: IBookLandingPage = {
  comingSoon: false,
  authorName: undefined,
  bookName: "",
  authorImage: "",
  authorDescription: [""],
  authorDesignation: "",
  companyIcon: "",
  bookImage: "",
  bookBackImage: "",
  bookSideImage: "",
  bookDescription: [""],
  shortDescription: "",
  keyPoints: [{ name: "" }],
  bookIncludes: [{ name: "" }],
  whatYouWillLearn: [""],
  language: "",
  isbn: "",
  overview: "removed",
  hardCopyPrice: { mrpPrice: 0, discountedPrice: 0 },
  softCopyPrice: { mrpPrice: 0, discountedPrice: 0 },
  rating: 0,
  numberOfPages: 10,
  reviewerPhoto: "",
  reviewerName: "",
  iframeUrl: "",
  thumbnailUrl: "",
  reviewerDescription: "",
  bookVideo: "",
  valueAddition: [{ title: "", valueAdditionImage: "", shortDescription: "" }],
  productHighlightText: "",
  chapters: [{ name: "", sections: [""] }],
  reviewImages: ["to-do"],
  sneakPeek: [""],
  productTitle: "",
  productSlug: "",
  productId: "",
  authorId: "",
  digitalProductId: 0,
  hardCoverAvailable: true,
  hardCoverProductId: 0,
  authorZlUid: "",
  authUser_name: "",
};

const BookLandingPageForm: React.FC = () => {
  const [formData, setFormData] = useState<IBookLandingPage>(initialFormData);
  const [pageId, setPageId] = useState<string>();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isPublish, setIsPublish] = useState(false);
  const [authors, setAuthors] = useState<
    {
      authorId: string;
      name: string;
      designation: string;
      avatar: any;
      description: string[];
      zl_uid: string;
      user_name: string;
    }[]
  >();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<
    {
      productId: string;
      hardcoverProductId: number;
      digitalProductId: number;
      slug: string;
      name: string;
      description: string;
      externalLink: string;
      bookImage: string;
      hardCoverMainPrice: string;
      hardCoverStrokedPrice: string;
      digitalMainPrice: string;
      digitalStrokedPrice: string;
    }[]
  >();
  const router = useNavigate();
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const { landingPageId } = useParams<{ landingPageId: string }>();

  const handleInputChange = (name: keyof IBookLandingPage, value: any) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, [name]: value };
      if (localStorage !== undefined && !landingPageId) {
        localStorage.setItem("formData", JSON.stringify(newFormData));
      }

      return newFormData;
    });
  };

  const addChapter = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      chapters: [...prevFormData.chapters, { name: "", sections: [""] }],
    }));
  };

  const removeChapter = (chapterIndex: number) => {
    setFormData((prevFormData) => {
      const newChapters = [...prevFormData.chapters];
      newChapters.splice(chapterIndex, 1);
      return {
        ...prevFormData,
        chapters: newChapters,
      };
    });
  };

  const handleAllProducts = async () => {
    try {
      const products = await getAllProducts({
        book_type: "hardcover",
        category_id: 6,
      });
      setProducts(
        products?.data?.map((product: any) => ({
          productId: product?.digital_pair?.id,
          hardcoverProductId: product?.id,
          digitalProductId: product?.digital_pair?.id,
          hardCoverMainPrice: product?.main_price,
          hardCoverStrokedPrice: product?.stroked_price,
          digitalMainPrice: product?.digital_pair?.main_price,
          digitalStrokedPrice: product?.digital_pair?.stroked_price,
          slug: product?.slug,
          name: product?.name,
          description: product?.description,
          externalLink: product?.external_link,
          bookImage: product?.thumbnail_image,
          bookName: product.name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleAllAuthors = async () => {
    try {
      const authors = await getAllAuthors();
      setAuthors(
        authors?.data?.map((author: any) => ({
          name: author?.name,
          designation: author?.designation?.length
            ? author?.designation[0]
            : "",
          avatar: generateUrlForImage(
            baseUrl as string,
            `${Endpoints.getAuthorAvatar}/`,
            author?._id
          ),
          longDescription: author?.longDescription,
          email: author?.email,
          authorId: author?._id,
          zl_uid: author?.zl_uid,
          user_name: author?.user_name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleImageChange = (
    index: number,
    url: string,
    fieldName: keyof IBookLandingPage
  ) => {
    const currentImages = formData[fieldName] as string[];
    const newImages = [...currentImages];
    newImages[index] = url;
    handleInputChange(fieldName, newImages);
  };

  const handleAddImage = (fieldName: keyof IBookLandingPage) => {
    const currentImages = formData[fieldName] as string[];
    const newImages = [...currentImages, ""];
    handleInputChange(fieldName, newImages);
  };

  useEffect(() => {
    handleAllAuthors();
  }, []);

  const handleChapterChange = (
    chapterIndex: number,
    field: keyof { name: string; sections: string[] },
    value: string | string[]
  ) => {
    setFormData((prevFormData) => {
      const newChapters = prevFormData.chapters.map((chapter, index) =>
        index === chapterIndex ? { ...chapter, [field]: value } : chapter
      );
      return {
        ...prevFormData,
        chapters: newChapters,
      };
    });
  };

  const handleCreateLandingPage = async (body: any) => {
    try {
      setLoading(true);
      if (landingPageId && pageId) {
        await updateLandingPage(body, pageId);
      } else {
        await createLandingPage(body);
      }
      if (localStorage !== undefined && !landingPageId) {
        localStorage.removeItem("formData");
      }

      router("/book-landing-page");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleGetSingleLandingPage = async (id: string) => {
    try {
      setLoading(true);
      const data = await getSingleLandingPage(id);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...data?.data?.form,
        ...(typeof data?.data?.form?.authorDescription === "string" && {
          authorDescription: [data.data.form.authorDescription],
        }),
      }));
      form.setFieldsValue(data?.data?.form);
      setPageId(data?.data?._id);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const filterFormData = (formData: IBookLandingPage): FilteredDataType => {
    const filteredData: FilteredDataType = {};

    (Object.keys(formData) as (keyof IBookLandingPage)[]).forEach((key) => {
      if (
        JSON.stringify(formData[key]) !== JSON.stringify(initialFormData[key])
      ) {
        const value = formData[key];

        if (value !== undefined) {
          filteredData[key] = value as any;
        }
      }
    });

    if (Array.isArray(formData?.keyPoints)) {
      const pageNumberObj = formData?.keyPoints.find(
        (item) => item.name === "pageNumber"
      );
      if (pageNumberObj && pageNumberObj?.value) {
        filteredData.numberOfPages = Number(pageNumberObj.value);
      }
    }

    filteredData.overview = "removed";
    filteredData.reviewImages = ["removed"];

    return filteredData;
  };

  const handleSubmit = async (isPublish?: boolean) => {
    const filteredFormData = filterFormData(formData);
    if (isPublish) {
      await form
        .validateFields()
        .then(() => {
          handleCreateLandingPage({
            authorName: formData?.authorId,
            form: filteredFormData,
            isUpcoming: formData?.comingSoon,
            name: formData?.productTitle,
            status: "publish",
            productSlug: formData?.productSlug,
          });
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    } else {
      handleCreateLandingPage({
        authorName: formData?.authorId,
        form: filteredFormData,
        isUpcoming: formData?.comingSoon,
        name: formData?.productTitle,
        status: "draft",
        productSlug: formData?.productSlug,
      });
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedFormData = localStorage.getItem("formData");
      if (storedFormData && !landingPageId) {
        const parsedFormData = JSON.parse(storedFormData);
        setFormData(parsedFormData);
        form.setFieldsValue(parsedFormData);
      }
      if (landingPageId) handleGetSingleLandingPage(landingPageId);
    }
  }, [landingPageId]);

  useEffect(() => {
    handleAllProducts();
    setFormData((prev) => ({ ...prev, language: "English" }));
    form.setFieldsValue({ language: "English" });
  }, []);

  const handleValueAdditionChange = (
    valueAdditionIndex: number,
    field: keyof {
      title: string;
      shortDescription: string;
      valueAdditionImage: string;
    },
    value: string
  ) => {
    setFormData((prevFormData) => {
      const newValueAdditions = prevFormData.valueAddition.map(
        (valueAddition, index) =>
          index === valueAdditionIndex
            ? { ...valueAddition, [field]: value }
            : valueAddition
      );
      return {
        ...prevFormData,
        valueAddition: newValueAdditions,
      };
    });
  };
  // Remove value addition
  const removeValueAddition = (index: number) => {
    setFormData((prevFormData) => {
      const newValueAdditions = prevFormData.valueAddition.filter(
        (_, i) => i !== index
      );
      return { ...prevFormData, valueAddition: newValueAdditions };
    });
  };
  // Add value addition
  const addValueAddition = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      valueAddition: [
        ...prevFormData.valueAddition,
        { title: "", shortDescription: "", valueAdditionImage: "" },
      ],
    }));
  };

  const handleKeyPointChange = (
    fieldIndex: number,
    field: keyof { name: string; value: string },
    value: string
  ) => {
    setFormData((prevFormData) => {
      const newKeyPoints = prevFormData.keyPoints.map(
        (additionalField, index) =>
          index === fieldIndex
            ? { ...additionalField, [field]: value }
            : additionalField
      );
      return {
        ...prevFormData,
        keyPoints: newKeyPoints,
      };
    });
  };

  // Remove Key Points field
  const removeKeyPoint = (index: number) => {
    setFormData((prevFormData) => {
      const newKeyPoints = prevFormData.keyPoints.filter((_, i) => i !== index);
      return { ...prevFormData, keyPoints: newKeyPoints };
    });
  };

  // Add additional field
  const addKeyPoint = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      keyPoints: [...prevFormData.keyPoints, { name: "", value: "" }],
    }));
  };

  // Book Includes handleChange
  const handleBookIncludesChange = (
    fieldIndex: number,
    field: keyof { name: string; value: string },
    value: string
  ) => {
    setFormData((prevFormData) => {
      const newKeyPoints = prevFormData.bookIncludes.map(
        (additionalField, index) =>
          index === fieldIndex
            ? { ...additionalField, [field]: value }
            : additionalField
      );
      return {
        ...prevFormData,
        bookIncludes: newKeyPoints,
      };
    });
  };

  // Remove Key Points field
  const removeBookIncludes = (index: number) => {
    setFormData((prevFormData) => {
      const newKeyPoints = prevFormData.bookIncludes.filter(
        (_, i) => i !== index
      );
      return { ...prevFormData, bookIncludes: newKeyPoints };
    });
  };

  // Add additional field
  const addBookIncludes = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      bookIncludes: [...prevFormData.bookIncludes, { name: "", value: "" }],
    }));
  };

  const handleSelectChangeProduct = ({
    value,
    name,
  }: {
    value: string;
    name: string;
  }) => {
    if (name === "product") {
      const product = products?.find((product) => product.productId === value);
      if (product) {
        const updatedProductData = {
          productId: product.productId,
          hardCopyPrice: {
            discountedPrice: Number(product.hardCoverMainPrice) || 0,
            mrpPrice: Number(product.hardCoverStrokedPrice) || 0,
          },
          softCopyPrice: {
            discountedPrice: Number(product.digitalMainPrice) || 0,
            mrpPrice: Number(product.digitalStrokedPrice) || 0,
          },
          productSlug: product.slug,
          productTitle: product.name,
          bookImage: product.bookImage,
          digitalProductId: product.digitalProductId,
          hardCoverProductId: product.hardcoverProductId,
          bookName: product.name,
        };
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...updatedProductData,
        }));
        form.setFieldsValue(updatedProductData);
      }
    } else if (name === "author") {
      const author = authors?.find((author) => author.authorId === value);
      if (author) {
        const updatedAuthorData = {
          authorDesignation: author.designation as string,
          authorId: author.authorId,
          authorImage: author.avatar,
          authorName: author.name,
          authorZlUid: author.zl_uid,
          authUser_name: author.user_name,
        };
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...updatedAuthorData,
        }));
        form.setFieldsValue(updatedAuthorData);
      }
    }
  };

  return (
    <>
      <ShouldRender check={!loading}>
        <Affix offsetTop={0}>
          <div className="bg-[#1E1E1F] px-5 py-3 flex justify-between items-center">
            <div className="flex items-center gap-x-4">
              <span className=" font-semibold">
                <Button
                  size="small"
                  onClick={() => router("/book-landing-page")}
                  type="text"
                  className="flex items-center"
                  icon={<ChevronLeftIcon className="w-3.5" />}
                >
                  Back
                </Button>
              </span>

              <span className=" font-semibold">
                Product landing page details
              </span>
            </div>

            <span className="flex gap-x-4">
              {" "}
              <ButtonDefault
                size={2}
                variant={ButtonVariants.GRAY}
                className="p-1 "
                // disabled={confirmLoading}
                // loading={confirmLoading}
                rounded={true}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <span className="flex text-sm  font-bold gap-x-2 px-4  whitespace-nowrap">
                  Save
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={2}
                variant={ButtonVariants.GRAY}
                className="p-1 "
                // disabled={confirmLoading}
                // loading={confirmLoading}
                rounded={true}
                onClick={() => {
                  setIsPublish(true);
                  handleSubmit(true);
                }}
              >
                <span className="flex text-sm  font-bold gap-x-2 px-4  whitespace-nowrap">
                  Publish
                </span>
              </ButtonDefault>
            </span>
          </div>
        </Affix>
        <div className="pt-4 px-4 md:px-10 pb-10">
          <Form
            form={form}
            layout="horizontal"
            className="flex gap-y-2 flex-col"
            initialValues={formData}
            labelCol={{ span: screens.xl ? 4 : screens.lg ? 6 : 8 }}
            wrapperCol={{ offset: 1 }}
            labelAlign="left"
            colon={false}
            requiredMark={false}
            scrollToFirstError
          >
            <Form.Item
              label={<span className="font-medium">Coming Soon</span>}
              name="comingSoon"
            >
              <div
                style={{ border: "1px solid #2D2D2D" }}
                className="flex justify-between items-center p-2 gap-x-4 rounded-lg bg-readerLightGrayBg"
              >
                <span className="text-sm text-[#737373]">
                  By turning this on, the product will be under as coming soon
                  status
                </span>
                <span>
                  <Switch
                    className="bg-lightgray"
                    value={formData?.comingSoon}
                    onChange={(check) => handleInputChange("comingSoon", check)}
                  />
                </span>
              </div>
            </Form.Item>
            <Form.Item
              label={<span className="font-medium">HardCover Available</span>}
              name="hardCoverAvailable"
            >
              <div
                style={{ border: "1px solid #2D2D2D" }}
                className="flex justify-between items-center p-2 gap-x-4 rounded-lg bg-readerLightGrayBg"
              >
                <span className="text-sm text-[#737373]">
                  By turning this off, the product hard cover button will
                  convert to coming soon
                </span>
                <span>
                  <Switch
                    className="bg-lightgray"
                    value={formData?.hardCoverAvailable}
                    onChange={(check) =>
                      handleInputChange("hardCoverAvailable", check)
                    }
                  />
                </span>
              </div>
            </Form.Item>
            <Form.Item
              label={<span className="font-medium">Product</span>}
              name="productTitle"
              valuePropName="productTitle"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the product title",
                },
              ]}
            >
              <Select
                showSearch
                style={{ height: 40 }}
                value={formData?.productId || undefined}
                placeholder="Select Product Type"
                filterOption={(input, option) =>
                  (option?.name ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.name ?? "")
                    ?.toLowerCase()
                    .localeCompare((optionB?.name ?? "")?.toLowerCase())
                }
                onChange={(value) =>
                  handleSelectChangeProduct({
                    value,
                    name: "product",
                  })
                }
                // disabled={}
                className="rounded-xl border border-bgDarkGray  antdSelect "
                options={products?.map((product) => ({
                  value: product?.productId,
                  label: product?.name,
                  name: product?.name?.toLowerCase(),
                }))}
              />
            </Form.Item>
            <Form.Item
              label={<span className="font-medium">Book Name</span>}
              name="bookName"
              valuePropName="bookName"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the book name",
                },
              ]}
            >
              <Input
                placeholder="Book Name"
                size="large"
                value={formData.bookName}
                onChange={(e) => handleInputChange("bookName", e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Product Slug</span>}
              name="productSlug"
              valuePropName="productSlug"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the product slug",
                },
              ]}
            >
              <Input
                placeholder="{product slug}"
                disabled={true}
                className="  border-gray-800"
                prefix="https://zebralearn.com/books/"
                size="large"
                value={formData.productSlug}
                onChange={(e) =>
                  handleInputChange("productSlug", e.target.value)
                }
              />
              <span className="text-[#737373]">
                example: https://zebralearn.com/books/
                <b>financial-statement-analytics</b>
              </span>
            </Form.Item>

            <Form.Item
              label={
                <span className="font-medium">Product Short Description</span>
              }
              name="shortDescription"
              valuePropName="shortDescription"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the product short description",
                },
              ]}
            >
              <Input
                placeholder="Enter short description"
                className="  border-gray-800"
                size="large"
                value={formData.shortDescription}
                onChange={(e) =>
                  handleInputChange("shortDescription", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Author Name</span>}
              name="authorId"
              valuePropName="authorId"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the author name",
                },
              ]}
            >
              <Select
                showSearch
                style={{ height: 40 }}
                placeholder="Select Author Name"
                filterOption={(input, option) =>
                  (option?.name ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.name ?? "")
                    ?.toLowerCase()
                    .localeCompare((optionB?.name ?? "")?.toLowerCase())
                }
                value={formData?.authorId || undefined}
                onChange={(value) => {
                  handleSelectChangeProduct({
                    value,
                    name: "author",
                  });
                }}
                className="rounded-xl bg-readerLightGrayBg placeholder:font-medium border border-bgDarkGray"
                options={authors?.map((product) => ({
                  value: product?.authorId,
                  label: product?.name,
                  name: product?.name.toLowerCase(),
                  authorZlUid: product?.zl_uid,
                  authUser_name: product?.user_name,
                }))}
              />
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Author Image</span>}
              name="authorImage"
              valuePropName="authorImage"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the author image",
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  This picture will be visible to all. Aspect Ratio (136 / 191)
                </span>
                <span className="w-1/2">
                  <ImageUploadUrl
                    uploadAction="Enter author image"
                    maxFileCount={1}
                    aspectRatio={136 / 191}
                    updateText={"Drag and drop files here or click to upload"}
                    onChange={(value) => {
                      handleInputChange("authorImage", value);
                    }}
                    defaultImage={
                      typeof formData.authorImage === "string"
                        ? formData.authorImage
                        : ""
                    }
                    clearImage={true}
                  />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              label={
                <div className="flex font-medium flex-col">
                  <span className="font-medium">Author Description </span>{" "}
                  <span> (Max 6)</span>
                </div>
              }
              name="authorDescription"
              valuePropName="authorDescription"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the author description",
                },
                {
                  validator: () => {
                    if (
                      !formData?.authorDescription ||
                      (formData?.authorDescription?.length === 1 &&
                        formData?.authorDescription[0]?.trim() === "")
                    ) {
                      return Promise.reject(
                        new Error("Author Description cannot be empty")
                      );
                    }
                    if (formData?.bookDescription?.length > 6) {
                      return Promise.reject(
                        new Error("You can only add up to 6 descriptions")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData?.authorDescription?.map((desc, index) => (
                <div key={index} className="flex mb-2">
                  <Input.TextArea
                    className="border-gray-800"
                    size="large"
                    placeholder={`Description ${index + 1}`}
                    value={desc}
                    onChange={(e) => {
                      const newDescriptions = [...formData.authorDescription];
                      newDescriptions[index] = e.target.value;
                      handleInputChange("authorDescription", newDescriptions);
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      const newDescriptions = [...formData.authorDescription];
                      newDescriptions.splice(index, 1);
                      handleInputChange("authorDescription", newDescriptions);
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                disabled={formData?.authorDescription?.length >= 6}
                onClick={() => {
                  handleInputChange("authorDescription", [
                    ...formData.authorDescription,
                    "",
                  ]);
                }}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add More
              </Button>
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Author Designation</span>}
              name="authorDesignation"
              valuePropName="authorDesignation"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the author designation",
                },
              ]}
            >
              <Input
                placeholder="Enter author designation"
                className="  border-gray-800"
                size="large"
                value={formData.authorDesignation}
                onChange={(e) =>
                  handleInputChange("authorDesignation", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Company Icon</span>}
              name="companyIcon"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the company icon",
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  Add Author’s company/rewards image. Aspect Ratio: (1 / 1)
                </span>
                <span className="w-1/2">
                  <ImageUploadUrl
                    uploadAction="Enter company icon"
                    maxFileCount={1}
                    updateText={"Drag and drop files here or click to upload"}
                    onChange={(value) =>
                      handleInputChange("companyIcon", value)
                    }
                    defaultImage={
                      typeof formData.companyIcon === "string"
                        ? formData.companyIcon
                        : ""
                    }
                    clearImage={true}
                  />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Book Image</span>}
              name="bookImage"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the book image",
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  This picture will be visible to everyone (1748px x 2480px).
                  Aspect Ratio: (437 / 620)
                </span>
                <span className="w-1/2">
                  <ImageUploadUrl
                    uploadAction="Enter book Image"
                    maxFileCount={1}
                    requiredWidth={1748}
                    requiredHeight={2480}
                    aspectRatio={437 / 620}
                    updateText={"Drag and drop files here or click to upload"}
                    onChange={(value) => handleInputChange("bookImage", value)}
                    defaultImage={
                      typeof formData.bookImage === "string"
                        ? formData.bookImage
                        : ""
                    }
                    clearImage={true}
                  />
                </span>
              </div>
            </Form.Item>
            <Form.Item
              label={<span className="font-medium">Book back image</span>}
              name="bookBackImage"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the bookBackImage image",
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  This picture will be visible to everyone (1748px x 2480px).
                  Aspect Ratio: (437 / 620)
                </span>
                <span className="w-1/2">
                  <ImageUploadUrl
                    uploadAction="Enter book Image"
                    maxFileCount={1}
                    aspectRatio={437 / 620}
                    requiredWidth={1748}
                    requiredHeight={2480}
                    updateText={"Drag and drop files here or click to upload"}
                    onChange={(value) =>
                      handleInputChange("bookBackImage", value)
                    }
                    defaultImage={
                      typeof formData.bookBackImage === "string"
                        ? formData.bookBackImage
                        : ""
                    }
                    clearImage={true}
                  />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Book Side image</span>}
              name="bookSideImage"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the bookSideImage image",
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  This picture will be visible to everyone (295px x 2480px).
                  Aspect Ratio: (59 / 496)
                </span>
                <span className="w-1/2">
                  <ImageUploadUrl
                    uploadAction="Enter book Image"
                    maxFileCount={1}
                    aspectRatio={59 / 496}
                    requiredWidth={295}
                    requiredHeight={2480}
                    updateText={"Drag and drop files here or click to upload"}
                    onChange={(value) =>
                      handleInputChange("bookSideImage", value)
                    }
                    defaultImage={
                      typeof formData.bookSideImage === "string"
                        ? formData.bookSideImage
                        : ""
                    }
                    clearImage={true}
                  />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              label={
                <div className="flex font-medium flex-col">
                  <span className="font-medium">Book Description </span>{" "}
                  <span> (Max 6)</span>
                </div>
              }
              name="bookDescription"
              valuePropName="bookDescription"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the book description",
                },
                {
                  validator: () => {
                    if (
                      !formData?.bookDescription ||
                      (formData?.bookDescription?.length === 1 &&
                        formData?.bookDescription[0]?.trim() === "")
                    ) {
                      return Promise.reject(
                        new Error("Book description cannot be empty")
                      );
                    }
                    if (formData?.bookDescription?.length > 6) {
                      return Promise.reject(
                        new Error("You can only add up to 6 descriptions")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData.bookDescription.map((desc, index) => (
                <div key={index} className="flex mb-2">
                  <Input.TextArea
                    className="border-gray-800"
                    size="large"
                    placeholder={`Description ${index + 1}`}
                    value={desc}
                    onChange={(e) => {
                      const newDescriptions = [...formData.bookDescription];
                      newDescriptions[index] = e.target.value;
                      handleInputChange("bookDescription", newDescriptions);
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      const newDescriptions = [...formData.bookDescription];
                      newDescriptions.splice(index, 1);
                      handleInputChange("bookDescription", newDescriptions);
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                disabled={formData?.bookDescription?.length >= 6}
                onClick={() => {
                  handleInputChange("bookDescription", [
                    ...formData.bookDescription,
                    "",
                  ]);
                }}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add More
              </Button>
            </Form.Item>

            <Form.Item
              label={
                <div className="flex font-medium flex-col">
                  <span className="font-medium">What You Will Learn</span>{" "}
                  <span> (Max 6)</span>
                </div>
              }
              name="whatYouWillLearn"
              valuePropName="whatYouWillLearn"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter what you will learn points",
                },
                {
                  validator: () => {
                    if (
                      !formData?.whatYouWillLearn ||
                      (formData?.whatYouWillLearn?.length === 1 &&
                        formData?.authorDescription[0]?.trim() === "")
                    ) {
                      return Promise.reject(
                        new Error("will learn points cannot be empty")
                      );
                    }
                    if (formData?.whatYouWillLearn?.length > 6) {
                      return Promise.reject(
                        new Error("You can only add up to 6 points")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData?.whatYouWillLearn?.map((point, index) => (
                <div key={index} className="flex mb-2">
                  <Input.TextArea
                    className="border-gray-800"
                    size="large"
                    placeholder={`Points ${index + 1}`}
                    value={point}
                    onChange={(e) => {
                      const newDescriptions = [...formData.whatYouWillLearn];
                      newDescriptions[index] = e.target.value;
                      handleInputChange("whatYouWillLearn", newDescriptions);
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      const newDescriptions = [...formData.whatYouWillLearn];
                      newDescriptions.splice(index, 1);
                      handleInputChange("whatYouWillLearn", newDescriptions);
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                disabled={formData?.whatYouWillLearn?.length >= 6}
                onClick={() => {
                  handleInputChange("whatYouWillLearn", [
                    ...formData.whatYouWillLearn,
                    "",
                  ]);
                }}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add More
              </Button>
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Key Points</span>}
              name="keyPoints"
              valuePropName="keyPoints"
              rules={[
                {
                  required: isPublish,
                  message: "Please add at least one additional field",
                },
                {
                  validator: () => {
                    if (
                      !formData?.keyPoints ||
                      formData?.keyPoints?.length === 0 ||
                      formData.keyPoints.some((field) => !field.name.trim())
                    ) {
                      return Promise.reject(
                        new Error("Field name cannot be empty")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData.keyPoints.map((field, fieldIndex) => (
                <div key={fieldIndex} className="mb-5">
                  <span className="flex justify-between">
                    <div className="text-primary underline">
                      Field {fieldIndex + 1}
                    </div>
                    <Button
                      type="link"
                      className="flex gap-2 items-center"
                      onClick={() => removeKeyPoint(fieldIndex)}
                    >
                      <MinusOutlined className="w-4" /> Remove Field
                    </Button>
                  </span>
                  <Form.Item
                    label={<span className="font-medium">Field Name</span>}
                    name={`keyPoints[${fieldIndex}].name`}
                    valuePropName={`keyPoints[${fieldIndex}].name`}
                  >
                    <Select
                      placeholder="Select field name"
                      className="border-gray-800"
                      size="large"
                      value={field.name === "" ? undefined : field.name}
                      onChange={(value) =>
                        handleKeyPointChange(fieldIndex, "name", value)
                      }
                      options={[
                        {
                          label: "Finish in (days and per day)",
                          value: "finishIn",
                        },
                        {
                          label: "Get a Certificate",
                          value: "getCertificate",
                        },
                        {
                          label: "No. of Design Frameworks: ",
                          value: "noOfDesignFrameworks",
                        },
                        { label: "Language", value: "language" },
                        { label: "Pages Number", value: "pageNumber" },
                        { label: "Level", value: "level" },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span className="font-medium">Field Value</span>}
                    name={`keyPoints[${fieldIndex}].value`}
                    valuePropName={`keyPoints[${fieldIndex}].value`}
                  >
                    <Input
                      placeholder="Enter field value (optional)"
                      className="border-gray-800"
                      size="large"
                      value={field.value}
                      onChange={(e) =>
                        handleKeyPointChange(
                          fieldIndex,
                          "value",
                          e.target.value
                        )
                      }
                    />
                  </Form.Item>
                </div>
              ))}
              <Button
                type="dashed"
                onClick={addKeyPoint}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add Field
              </Button>
            </Form.Item>

            <ShouldRender check={!formData?.comingSoon}>
              <Divider />
              <Form.Item
                label={<span className="font-medium">Language</span>}
                name="language"
                valuePropName="language"
                rules={[
                  {
                    required: isPublish,
                    message: "Please select the book language",
                  },
                ]}
              >
                {/* <Input
             placeholder="Enter book language"
             className="  border-gray-800"
             size="large"
             value={formData.language}
             onChange={(e) => handleInputChange("language", e.target.value)}
           /> */}
                <Select
                  style={{ height: 40 }}
                  value={formData.language || undefined}
                  placeholder="Select Book Type"
                  onChange={(value) => handleInputChange("language", value)}
                  className="rounded-xl border border-bgDarkGray  antdSelect "
                  options={[
                    {
                      value: "hindi",
                      label: "Hindi",
                    },
                    {
                      value: "english",
                      label: "English",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label={<span className="font-medium">ISBN</span>}
                name="isbn"
                valuePropName="isbn"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter the book isbn",
                  },
                ]}
              >
                <Input
                  placeholder="Enter ISBN"
                  className="  border-gray-800"
                  size="large"
                  value={formData.isbn}
                  onChange={(e) => handleInputChange("isbn", e.target.value)}
                />
              </Form.Item>
              <Form.Item
                label={<span className="font-medium">Book Includes</span>}
                name="bookIncludes"
                valuePropName="bookIncludes"
                rules={[
                  {
                    required: isPublish,
                    message: "Please add at least one book Includes",
                  },
                  {
                    validator: () => {
                      if (
                        !formData?.bookIncludes ||
                        formData?.bookIncludes?.length === 0 ||
                        formData.bookIncludes.some(
                          (field) => !field.name.trim()
                        )
                      ) {
                        return Promise.reject(
                          new Error("Field name cannot be empty")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {formData.bookIncludes.map((field, fieldIndex) => (
                  <div key={fieldIndex} className="mb-5">
                    <span className="flex justify-between">
                      <div className="text-primary underline">
                        Field {fieldIndex + 1}
                      </div>
                      <Button
                        type="link"
                        className="flex gap-2 items-center"
                        onClick={() => removeBookIncludes(fieldIndex)}
                      >
                        <MinusOutlined className="w-4" /> Remove Field
                      </Button>
                    </span>
                    <Form.Item
                      label={<span className="font-medium">Field Name</span>}
                      name={`bookIncludes[${fieldIndex}].name`}
                      valuePropName={`bookIncludes[${fieldIndex}].name`}
                    >
                      <Select
                        placeholder="Select field name"
                        className="border-gray-800"
                        size="large"
                        value={field.name === "" ? undefined : field.name}
                        onChange={(value) =>
                          handleBookIncludesChange(fieldIndex, "name", value)
                        }
                        options={[
                          {
                            label: "On-Demand video (number of hours)",
                            value: "onDemandVideo",
                          },
                          {
                            label: "Exercises (number)",
                            value: "exercises",
                          },
                          {
                            label: "Article (number of article)",
                            value: "articles",
                          },
                          {
                            label:
                              "Downloadable Resources (number of resources)",
                            value: "downloadableResources",
                          },
                          {
                            label: "Full Lifetime Access",
                            value: "fullLifetimeAccess",
                          },
                          {
                            label: "Certificate of completion",
                            value: "certificate",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      label={<span className="font-medium">Field Value</span>}
                      name={`bookIncludes[${fieldIndex}].value`}
                      valuePropName={`bookIncludes[${fieldIndex}].value`}
                    >
                      <Input
                        placeholder="Enter field value (optional)"
                        className="border-gray-800"
                        size="large"
                        value={field.value}
                        onChange={(e) =>
                          handleBookIncludesChange(
                            fieldIndex,
                            "value",
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={addBookIncludes}
                  className="flex gap-x-2 items-center mb-4"
                >
                  <PlusOutlined /> Add Field
                </Button>
              </Form.Item>

              {/* <Form.Item
                label={<span className="font-medium">Overview</span>}
                name="overview"
                valuePropName="overview"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter the overview",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter overview"
                  className="  border-gray-800"
                  size="large"
                  rows={4}
                  value={formData.overview}
                  onChange={(e) =>
                    handleInputChange("overview", e.target.value)
                  }
                />
              </Form.Item> */}

              <Form.Item
                label={<span className="font-medium">Soft Copy Price</span>}
                name="softCopyPrice"
                valuePropName="softCopyPrice"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter soft copy price",
                  },
                  {
                    validator: () => {
                      if (
                        !formData?.softCopyPrice?.discountedPrice ||
                        !formData?.softCopyPrice?.mrpPrice
                      ) {
                        return Promise.reject(
                          new Error(
                            "Both MRP Price and Discounted Price are required"
                          )
                        );
                      }
                      if (
                        isNaN(formData?.softCopyPrice?.mrpPrice) ||
                        isNaN(formData?.softCopyPrice?.discountedPrice)
                      ) {
                        return Promise.reject(
                          new Error("Both prices must be valid numbers")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <div className="flex gap-x-4">
                  <Input
                    placeholder="MRP Price"
                    prefix="⟨₹⟩"
                    className="  border-gray-800 w-1/2"
                    size="large"
                    value={formData.softCopyPrice.mrpPrice || undefined}
                    onChange={(e) =>
                      handleInputChange("softCopyPrice", {
                        ...formData.softCopyPrice,
                        mrpPrice: parseFloat(e.target.value),
                      })
                    }
                  />
                  <Input
                    placeholder="Discounted Price"
                    prefix="⟨₹⟩"
                    className="  border-gray-800 w-1/2"
                    size="large"
                    value={formData.softCopyPrice.discountedPrice || undefined}
                    onChange={(e) =>
                      handleInputChange("softCopyPrice", {
                        ...formData.softCopyPrice,
                        discountedPrice: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
              </Form.Item>

              <Form.Item
                label={<span className="font-medium">Rating</span>}
                name="rating"
                valuePropName="rating"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter rating",
                  },
                  {
                    validator: () => {
                      if (!formData?.rating) {
                        return Promise.reject(new Error("Rating is required"));
                      }
                      if (isNaN(formData?.rating)) {
                        return Promise.reject(new Error("Rating is required"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  placeholder="Enter rating"
                  className="  border-gray-800 w-full"
                  size="large"
                  value={formData.rating || undefined}
                  onChange={(value) => {
                    handleInputChange("rating", value);
                  }}
                />
              </Form.Item>

              {/* <Form.Item
                label={<span className="font-medium">Number of Pages</span>}
                name="numberOfPages"
                valuePropName="numberOfPages"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter number Of pages",
                  },
                  {
                    validator: () => {
                      if (!formData?.numberOfPages) {
                        return Promise.reject(
                          new Error("Number Of Pages is required")
                        );
                      }
                      if (isNaN(formData?.numberOfPages)) {
                        return Promise.reject(
                          new Error("Number Of Pages is required")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter number of pages"
                  className="  border-gray-800"
                  size="large"
                  value={formData.numberOfPages || undefined}
                  onChange={(e) =>
                    handleInputChange("numberOfPages", parseInt(e.target.value))
                  }
                />
              </Form.Item> */}

              <Form.Item
                label={
                  <span className="font-medium">Product Highlight Text</span>
                }
                name="productHighlightText"
                valuePropName="productHighlightText"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter productHighlightText",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        typeof value === "string" &&
                        value.split(" ").length > 10
                      ) {
                        return Promise.reject(
                          "Product Highlight Text cannot be more than 10 words"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter productHighlightText"
                  className="border-gray-800"
                  size="large"
                  value={formData.productHighlightText || undefined}
                  onChange={(e) =>
                    handleInputChange("productHighlightText", e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                label={<span className="font-medium">Reviewer Photo</span>}
                name="reviewerPhoto"
                valuePropName="reviewerPhoto"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter reviewer photo",
                  },
                ]}
              >
                <div className="flex gap-x-4">
                  <span className="w-1/2 text-textGray">
                    Add photo of the person who’s review is featured. Aspect
                    Ratio : (1/1)
                  </span>
                  <span className="w-1/2">
                    <ImageUploadUrl
                      uploadAction="Enter book Image"
                      maxFileCount={1}
                      updateText={"Drag and drop files here or click to upload"}
                      onChange={(value) =>
                        handleInputChange("reviewerPhoto", value)
                      }
                      defaultImage={
                        typeof formData.reviewerPhoto === "string"
                          ? formData.reviewerPhoto
                          : ""
                      }
                      clearImage={true}
                    />
                  </span>
                </div>
              </Form.Item>

              <Form.Item
                label={<span className="font-medium">Reviewer Name</span>}
                name="reviewerName"
                valuePropName="reviewerName"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter reviewer name",
                  },
                ]}
              >
                <Input
                  placeholder="Enter reviewer name"
                  className="  border-gray-800"
                  size="large"
                  value={formData.reviewerName}
                  onChange={(e) =>
                    handleInputChange("reviewerName", e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="font-medium">Reviewer Description</span>
                }
                name="reviewerDescription"
                valuePropName="reviewerDescription"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter reviewer description",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter reviewer description"
                  className="  border-gray-800"
                  size="large"
                  rows={4}
                  value={formData.reviewerDescription}
                  onChange={(e) =>
                    handleInputChange("reviewerDescription", e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="font-medium">
                    Value Addition. <p>Aspect Ratio: (57 / 32)</p>
                  </span>
                }
                name="valueAddition"
                valuePropName="valueAddition"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter value valueAddition",
                  },
                  {
                    validator: () => {
                      if (
                        !formData?.valueAddition ||
                        (formData?.valueAddition.length === 1 &&
                          formData?.valueAddition[0]?.title?.trim() === "")
                      ) {
                        return Promise.reject(
                          new Error("Value addition title cannot be empty")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {formData?.valueAddition?.map(
                  (valueAddition, valueAdditionIndex) => (
                    <div key={valueAdditionIndex} className="mb-5">
                      <span className="flex justify-between">
                        <div className="text-primary underline">
                          Value Addition {valueAdditionIndex + 1}
                        </div>
                        <Button
                          type="link"
                          className="flex gap-2 items-center"
                          onClick={() =>
                            removeValueAddition(valueAdditionIndex)
                          }
                        >
                          <MinusOutlined className="w-4" /> Remove Value
                          Addition
                        </Button>
                      </span>

                      {/* Title Field */}
                      <Form.Item
                        label={<span className="font-medium">Title</span>}
                        name={`valueAddition[${valueAdditionIndex}].title`}
                        valuePropName={`valueAddition[${valueAdditionIndex}].title`}
                      >
                        <Input
                          placeholder="Enter title"
                          className="border-gray-800"
                          size="large"
                          value={valueAddition.title}
                          onChange={(e) =>
                            handleValueAdditionChange(
                              valueAdditionIndex,
                              "title",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>

                      {/* Short Description Field */}
                      <Form.Item
                        label={
                          <span className="font-medium">Short Description</span>
                        }
                        name={`valueAddition[${valueAdditionIndex}].shortDescription`}
                        valuePropName={`valueAddition[${valueAdditionIndex}].shortDescription`}
                      >
                        <Input
                          placeholder="Enter short description"
                          className="border-gray-800"
                          size="large"
                          value={valueAddition.shortDescription}
                          onChange={(e) =>
                            handleValueAdditionChange(
                              valueAdditionIndex,
                              "shortDescription",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>

                      {/* Image Upload */}
                      <Form.Item
                        label={
                          <span className="font-medium">
                            Value Addition Image
                          </span>
                        }
                        name={`valueAddition[${valueAdditionIndex}].valueAdditionImage`}
                      >
                        <span className="w-1/2">
                          <ImageUploadUrl
                            uploadAction="Enter book Image"
                            maxFileCount={1}
                            aspectRatio={57 / 32}
                            updateText={
                              "Drag and drop files here or click to upload"
                            }
                            onChange={(value) =>
                              handleValueAdditionChange(
                                valueAdditionIndex,
                                "valueAdditionImage",
                                value as string
                              )
                            }
                            defaultImage={
                              typeof formData?.valueAddition[valueAdditionIndex]
                                ?.valueAdditionImage === "string"
                                ? formData?.valueAddition[valueAdditionIndex]
                                    ?.valueAdditionImage
                                : ""
                            }
                            clearImage={true}
                          />
                        </span>
                      </Form.Item>
                    </div>
                  )
                )}
                <Button
                  type="dashed"
                  onClick={addValueAddition}
                  className="flex gap-x-2 items-center mb-4"
                >
                  <PlusOutlined /> Add Value Addition
                </Button>
              </Form.Item>

              <Form.Item
                label={<span className="font-medium">Chapters</span>}
                name="chapters"
                valuePropName="chapters"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter value chapters",
                  },
                  {
                    validator: () => {
                      if (
                        !formData?.chapters ||
                        (formData?.chapters.length === 1 &&
                          formData?.chapters[0]?.name.trim() === "")
                      ) {
                        return Promise.reject(
                          new Error("Chapter name cannot be empty")
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {formData.chapters.map((chapter, chapterIndex) => (
                  <div key={chapterIndex} className="mb-5">
                    <span className="flex justify-between">
                      <div className="text-primary underline">
                        Chapter {chapterIndex + 1}
                      </div>
                      <Button
                        type="link"
                        className="flex gap-2 items-center"
                        onClick={() => removeChapter(chapterIndex)}
                      >
                        <MinusOutlined className="w-4" /> Remove Chapter
                      </Button>
                    </span>
                    <Form.Item
                      label={<span className="font-medium">Chapter Name</span>}
                      name={`chapters[${chapterIndex}].name`}
                      valuePropName={`chapters[${chapterIndex}].name`}
                    >
                      <Input
                        placeholder="Enter chapter name"
                        className="  border-gray-800"
                        size="large"
                        value={chapter.name}
                        onChange={(e) =>
                          handleChapterChange(
                            chapterIndex,
                            "name",
                            e.target.value
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label={
                        <span className="font-medium">Chapter Sections</span>
                      }
                      name={`chapters[${chapterIndex}].sections`}
                    >
                      {chapter.sections.map((section, sectionIndex) => (
                        <div key={sectionIndex} className="flex mb-2">
                          <Input
                            className="  border-gray-800"
                            size="large"
                            placeholder={`Section ${sectionIndex + 1}`}
                            value={section}
                            onChange={(e) => {
                              const newSections = [...chapter.sections];
                              newSections[sectionIndex] = e.target.value;
                              handleChapterChange(
                                chapterIndex,
                                "sections",
                                newSections
                              );
                            }}
                          />
                          <Button
                            type="link"
                            onClick={() => {
                              const newSections = [...chapter.sections];
                              newSections.splice(sectionIndex, 1);
                              handleChapterChange(
                                chapterIndex,
                                "sections",
                                newSections
                              );
                            }}
                          >
                            <MinusOutlined />
                          </Button>
                        </div>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() => {
                          handleChapterChange(chapterIndex, "sections", [
                            ...chapter.sections,
                            "",
                          ]);
                        }}
                        className="flex gap-x-2 items-center mb-4"
                      >
                        <PlusOutlined /> Add Section
                      </Button>
                    </Form.Item>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={addChapter}
                  className="flex gap-x-2 items-center mb-4"
                >
                  <PlusOutlined /> Add Chapter
                </Button>
              </Form.Item>

              {/* <Form.Item
              label={<span className="font-medium">Review Images</span>}
              name="reviewImages"
              valuePropName="reviewImages"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter review images",
                },
                {
                  validator: () => {
                    if (
                      !formData?.reviewImages ||
                      (formData?.reviewImages.length === 1 &&
                        formData?.reviewImages[0]?.trim() === "")
                    ) {
                      return Promise.reject(
                        new Error("review images are required")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  Add value addition image
                </span>
                <div className="flex  gap-5 flex-wrap">
                  {formData.reviewImages.map((image, index) => (
                    <div key={index} className="flex mb-2">
                      <span className="relative">
                        <ImageUploadUrl
                          uploadAction="Enter Value addition"
                          maxFileCount={1}
                          updateText={
                            "Drag and drop files here or click to upload"
                          }
                          onChange={(value) =>
                            handleImageChange(
                              index,
                              value as string,
                              "reviewImages"
                            )
                          }
                          defaultImage={typeof image === "string" ? image : ""}
                          clearImage={true}
                        />
                        <Button
                          type="link"
                          className="absolute -top-5 right-0"
                          onClick={() => {
                            const newImages = [...formData.reviewImages];
                            newImages.splice(index, 1);
                            handleInputChange("reviewImages", newImages);
                          }}
                        >
                          <MinusOutlined /> Remove
                        </Button>
                      </span>
                    </div>
                  ))}

                  <div
                    onClick={() => handleAddImage("reviewImages")}
                    className="flex border cursor-pointer group min-h-24  hover:border-primary border-bgDarkGray border-dashed rounded-lg px-5 mb-3 bg-readerLightGrayBg items-center"
                  >
                    <div className="flex flex-col gap-y-2 text-textGray group-hover:text-primary justify-center text-center">
                      <span className="flex justify-center">
                        <PlusOutlined />
                      </span>
                      Add More
                    </div>
                  </div>
                </div>
              </div>
            </Form.Item> */}
              <Form.Item
                label={<span className="font-medium">Iframe</span>}
                name="iframe"
                valuePropName="iframe"
              >
                <div className="mb-5">
                  <Form.Item
                    label={
                      <span className="font-medium">
                        Thumbnail <p>Aspect Ratio: (343 : 193)</p>
                      </span>
                    }
                    name="thumbnailUrl"
                    valuePropName="thumbnailUrl"
                    rules={[
                      {
                        required: isPublish,
                        message: "Thumbnail URL is required.",
                      },
                    ]}
                  >
                    <span className="w-1/2">
                      <ImageUploadUrl
                        uploadAction="Enter book Image"
                        maxFileCount={1}
                        aspectRatio={343 / 193}
                        updateText="Drag and drop files here or click to upload"
                        onChange={(url) => {
                          handleInputChange("thumbnailUrl", url);
                        }}
                        defaultImage={
                          typeof formData?.thumbnailUrl === "string"
                            ? formData?.thumbnailUrl
                            : ""
                        }
                        clearImage={true}
                      />
                    </span>
                  </Form.Item>

                  <Form.Item
                    label={<span className="font-medium">Iframe URL</span>}
                    name="iframeUrl"
                    valuePropName="iframeUrl"
                    rules={[
                      {
                        required: isPublish,
                        message: "Iframe URL is required.",
                      },
                      {
                        validator: () => {
                          // Check if the URL is empty or invalid
                          if (
                            !formData.iframeUrl ||
                            formData.iframeUrl.trim() === ""
                          ) {
                            return Promise.reject(
                              new Error("Iframe URL cannot be empty.")
                            );
                          }
                          // Simple regex pattern to check if the input is a valid URL
                          const urlPattern = new RegExp(
                            "^(https?:\\/\\/)?" + // protocol
                              "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
                              "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                              "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                              "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                              "(\\#[-a-z\\d_]*)?$",
                            "i"
                          );

                          if (!urlPattern.test(formData.iframeUrl)) {
                            return Promise.reject(
                              new Error("Please enter a valid URL.")
                            );
                          }

                          // If everything is valid, resolve the promise
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter iframe URL"
                      className="border-gray-800"
                      value={formData.iframeUrl}
                      size="large"
                      onChange={(e) => {
                        handleInputChange("iframeUrl", e.target.value);
                      }}
                    />
                  </Form.Item>
                </div>
              </Form.Item>

              <Form.Item
                label={<span className="font-medium">Sneak Peek</span>}
                name="sneakPeek"
                valuePropName="sneakPeek"
                rules={[
                  {
                    required: isPublish,
                    message: "Please enter sneak peek",
                  },
                  {
                    validator: () => {
                      if (
                        formData?.sneakPeek?.length === 1 &&
                        formData?.sneakPeek[0]?.trim() === ""
                      ) {
                        return Promise.reject(
                          new Error("sneak peek images are required")
                        );
                      }
                      if (formData?.sneakPeek?.length < 2) {
                        return Promise.reject(
                          new Error("At least 2 images are required")
                        );
                      }
                      if (formData?.sneakPeek?.length % 2 !== 0) {
                        return Promise.reject(
                          new Error(
                            "Number of images should be in multiples of 2"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <div className="flex gap-x-4">
                  <span className="w-1/2 text-textGray">
                    Please add value addition images (must be more than 2 and in
                    multiples of 2). Aspect Ratio: (339 / 476)
                  </span>
                  <div className="flex  gap-5 flex-wrap">
                    {formData.sneakPeek.map((image, index) => (
                      <div key={index} className="flex mb-2 relative">
                        <span className="min-w-36">
                          <ImageUploadUrl
                            uploadAction="Enter Value addition"
                            maxFileCount={1}
                            aspectRatio={339 / 476}
                            updateText={
                              "Drag and drop files here or click to upload"
                            }
                            onChange={(value) =>
                              handleImageChange(
                                index,
                                value as string,
                                "sneakPeek"
                              )
                            }
                            defaultImage={
                              typeof image === "string" ? image : ""
                            }
                            clearImage={true}
                          />
                          <Button
                            type="link"
                            className="absolute -top-5 right-0"
                            onClick={() => {
                              const newImages = [...formData.sneakPeek];
                              newImages.splice(index, 1);
                              handleInputChange("sneakPeek", newImages);
                            }}
                          >
                            <MinusOutlined /> Remove
                          </Button>
                        </span>
                      </div>
                    ))}

                    <div
                      onClick={() => handleAddImage("sneakPeek")}
                      className="flex border cursor-pointer group min-h-24  hover:border-primary border-bgDarkGray border-dashed rounded-lg px-5 mb-3 bg-readerLightGrayBg items-center"
                    >
                      <div className="flex flex-col gap-y-2 text-textGray group-hover:text-primary justify-center text-center">
                        <span className="flex justify-center">
                          <PlusOutlined />
                        </span>
                        Add More
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
            </ShouldRender>
          </Form>
        </div>
      </ShouldRender>
      <ShouldRender check={loading}>
        <CustomLoader />
      </ShouldRender>
    </>
  );
};

export default BookLandingPageForm;
