import React, { useState, useEffect } from "react";
import { Table, Input, Pagination, Select, Button } from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";

import moment from "moment";
import { DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { getOnboardingUserSubmissions } from "../../services/personalityTest";
import { formatErrorObject } from "../../utils/lib/auth";
import { notify } from "../shared/basic/notify";
const { RangePicker } = DatePicker;

interface Questions {
  text: string;
  _id: string;
}

interface Options {
  label: string;
  value: string;
  _id: string;
}

interface Answer {
  questionId: Questions;
  optionId: Options[];
  _id: string;
}

interface Category {
  _id: string;
  name: string;
  valueTypes: string[];
}

interface Submission {
  _id: string;
  zl_uid: string | null;
  email: string | null;
  answers: Answer[];
  category: Category;
  createdAt: string;
  updatedAt: string;
}

interface UserSubmission {
  user: string;
  submissions: Submission[];
}

interface PaginationInfo {
  page: number;
  limit: number;
  total: number;
  totalPages: number;
}

const OnboardingUserSubmissionTable: React.FC = () => {
  const [data, setData] = useState<UserSubmission[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo>({
    page: 1,
    limit: 10,
    total: 0,
    totalPages: 1,
  });
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [search, setSearch] = useState<string>("");
  const [dateRange, setDateRange] = useState<
    { start: string; end: string } | undefined
  >(undefined);
  const router = useNavigate();

  const fetchData = async (params: {
    page?: number;
    limit?: number;
    sort?: "asc" | "desc";
    search?: string;
    dateRange?: { start: string; end: string };
  }) => {
    try {
      setLoading(true);
      const submissions = await getOnboardingUserSubmissions(params);
      setData(submissions.data.results);
      setPagination(submissions.data.pagination);
    } catch (error) {
      notify(formatErrorObject(error), "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      page: pagination.page,
      limit: pagination.limit,
      sort: sortOrder,
      search,
      dateRange,
    });
  }, [pagination.page, pagination.limit, sortOrder, search, dateRange]);
  const onSearch = (value: string) => {
    setSearch(value);
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  const onTableChange = (page: number, pageSize: number) => {
    setPagination({ ...pagination, page, limit: pageSize });
  };

  const onSortChange = (value: "asc" | "desc") => {
    setSortOrder(value);
  };

  const userColumns: ColumnsType<UserSubmission> = [
    {
      title: "ZL UID",
      dataIndex: "submissions",
      key: "zl_uid",
      render: (submissions) =>
        submissions.length > 0 ? submissions[0].zl_uid : "N/A",
    },
    {
      title: "Email",
      dataIndex: "submissions",
      key: "email",
      render: (submissions) =>
        submissions.length > 0 ? submissions[0].email : "N/A",
    },

    {
      title: "Created At",
      dataIndex: "submissions",
      key: "createdAt",
      render: (submissions) =>
        submissions.length > 0
          ? moment(submissions[0].createdAt).format("Do MMM YYYY")
          : "N/A",
    },
  ];

  return (
    <div className="bg-readerLightGrayBg rounded-xl pb-5 ">
      <div className="py-4 pr-6 pl-3 flex flex-col justify-between md:flex-row gap-x-2 items-center">
        <p className="font-semibold items-center gap-x-2 flex justify-start text-start text-base lg:text-lg whitespace-nowrap">
          <Button
            onClick={() => router("/onboarding-questions")}
            icon={<ChevronLeftIcon className="w-3 " />}
            type="text"
          >
            Back
          </Button>{" "}
          Onboarding User Submissions{" "}
        </p>
        <span className="flex gap-x-4">
          <Input.Search
            placeholder="Search by ZL UID or Email"
            onSearch={onSearch}
            style={{ marginLeft: 8, width: 250 }}
            enterButton={
              <div>
                <SearchOutlined />
              </div>
            }
          />
          <Select
            value={sortOrder}
            onChange={onSortChange}
            options={[
              { value: "asc", label: "Ascending" },
              { value: "desc", label: "Descending" },
            ]}
            style={{ width: 150 }}
          />
          <RangePicker
            onChange={(dates, dateStrings) => {
              if (dates) {
                setDateRange({
                  start: dateStrings[0],
                  end: dateStrings[1],
                });
              } else {
                setDateRange(undefined);
              }
              setPagination((prev) => ({ ...prev, page: 1 }));
            }}
            style={{ marginLeft: 8, width: 250 }}
            placeholder={["Start Date", "End Date"]}
          />
        </span>
      </div>
      <div className="customScrollBar">
        <Table<UserSubmission>
          rowKey="user"
          dataSource={data}
          columns={userColumns}
          loading={loading}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Table<Answer>
                  rowKey="_id"
                  dataSource={record.submissions.flatMap(
                    (submission) => submission.answers
                  )}
                  columns={[
                    {
                      title: "Question",
                      dataIndex: ["questionId", "text"],
                      key: "question",
                    },
                    {
                      title: "Answer",
                      dataIndex: "optionId",
                      key: "answer",
                      render: (optionIds: Options[]) =>
                        optionIds.map((option) => option.label).join(" , "),
                    },
                  ]}
                  pagination={false}
                  size="small"
                  showHeader
                />
              );
            },
          }}
        />
        <Pagination
          current={pagination.page}
          pageSize={pagination.limit}
          total={pagination.total}
          onChange={onTableChange}
          style={{ marginTop: 16, textAlign: "right" }}
        />
      </div>
    </div>
  );
};

export default OnboardingUserSubmissionTable;
