import { Divider, Input, InputNumber, Modal, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { notify } from "../../shared/basic/notify";
import { formateErrorObject } from "../../shared/formateErrorObject";
import {
  createCourse,
  editCourse,
  getAllAuthors,
  getAllCategories,
  getAllProducts,
  getSingleBook,
} from "../../../services/dashboard";
import UploadFile from "../UploadFile";
import extractErrorMessage from "../../shared/basic/formateError";
import { generateUrlForImage } from "../Books";
import { Endpoints } from "../../../network";
import { ShouldRender } from "../../shared/basic/ShouldRender";

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  type?: string;
  bookId?: number;
  isCourse?: boolean;
}

interface Time {
  hours: number | undefined;
  minutes: number | undefined;
}

const CreateCourseModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  type,
  bookId,
  isCourse,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [authors, setAuthors] = useState<
    {
      value: string;
      label: string;
    }[]
  >();
  const [categories, setCategories] = useState<
    {
      value: string;
      label: string;
    }[]
  >();
  const [products, setProducts] = useState<
    {
      productId: string;
      hardcoverProductId: string;
      digitalProductId: string;
      slug: string;
      name: string;
      description: string;
      externalLink: string;
      stroked_price?: number;
      main_price?: number;
    }[]
  >();
  const [time, setTime] = useState<Time>({
    hours: undefined,
    minutes: undefined,
  });

  const [formData, setFormData] = useState<{
    title: string;
    author: {
      name: string | undefined;
      value: string;
    };
    product: {
      productId: string;
      hardcoverProductId: string;
      digitalProductId: string;
      slug: string;
      name?: string;
    };
    isExternalLink: boolean;
    description: string;
    type: string;
    coverImage: any;
    category_id: string;
    slug: string;
    duration: number;
    isListed: boolean;
    featured: boolean;
    stroked_price?: number;
    main_price?: number;
  }>({
    title: "",
    author: {
      name: undefined,
      value: "",
    },
    product: {
      productId: "",
      slug: "",
      digitalProductId: "",
      hardcoverProductId: "",
      name: "",
    },
    isExternalLink: false,
    description: "",
    type: "",
    category_id: "",
    coverImage: undefined,
    duration: 0,
    slug: "",
    isListed: false,
    featured: false,
    stroked_price: undefined,
    main_price: undefined,
  });

  const [errors, setErrors] = useState({
    title: "",
    author: "",
    product: "",
    category_id: "",
    description: "",
    type: "",
    coverImage: "",
    slug: "",
    isListed: "",
    featured: "",
    duration: "",
    isExternalLink: "",
  });

  const handleCancel = () => {
    onCancel();
    setErrors({
      title: "",
      author: "",
      product: "",
      category_id: "",
      description: "",
      type: "",
      coverImage: "",
      slug: "",
      isListed: "",
      featured: "",
      isExternalLink: "",
      duration: "",
    });
    setFormData({
      title: "",
      author: {
        name: undefined,
        value: "",
      },
      product: {
        productId: "",
        slug: "",
        digitalProductId: "",
        hardcoverProductId: "",
        name: "",
      },
      isExternalLink: false,
      description: "",
      type: "",
      category_id: "",
      duration: 0,
      coverImage: undefined,
      slug: "",
      isListed: false,
      featured: false,
    });
    setTime({ hours: undefined, minutes: undefined });
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      handleCancel();
    }, 2000);
  };

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle input changes
  const handleTimeChange = (value: number | null, type: keyof Time) => {
    if (value === null) return;

    const updatedTime: Time = { ...time, [type]: value };
    setTime(updatedTime);

    const totalSeconds =
      (updatedTime.hours || 0) * 3600 + (updatedTime.minutes || 0) * 60;
    setFormData({
      ...formData,
      duration: totalSeconds,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors: {
      title: string;
      author: string;
      product: string;
      category_id: string;
      description: string;
      type: string;
      coverImage: string;
      slug: string;
      isListed: string;
      featured: string;
      duration: string;
      isExternalLink: string;
    } = {
      title: "",
      author: "",
      product: "",
      category_id: "",
      description: "",
      type: "",
      coverImage: "",
      slug: "",
      isListed: "",
      featured: "",
      isExternalLink: "",
      duration: "",
    };

    if (!formData.title) validationErrors.title = "Please enter title";
    if (!formData.author?.name)
      validationErrors.author = "Please select author";
    if (!formData.product?.productId)
      validationErrors.product = "Please select product";
    if (!formData.category_id?.length)
      validationErrors.category_id = "Please select category";
    if (!formData.type) validationErrors.type = "Please select type";
    if (!formData.description)
      validationErrors.description = "Please enter description";
    if (!formData.slug) validationErrors.slug = "Please enter slug";
    if (!formData.coverImage)
      validationErrors.coverImage = "Please enter cover image";
    if (!formData.duration)
      validationErrors.duration = "Please enter total duration";

    setErrors(validationErrors);

    if (!Object.values(validationErrors).every((error) => !error)) return;
    try {
      setConfirmLoading(true);

      const payload = {
        title: formData.title,
        authors: formData.author.name as string,
        author_list: [formData.author.value],
        productId: Number(formData.product.productId),
        coverImage: formData.coverImage,
        description: formData.description,
        slug: formData.slug,
        isExternalLink: formData.isExternalLink,
        type: formData.type,
        isListed: formData.isListed,
        featured: formData.featured,
        categories: [formData.category_id],
        main_price: formData.main_price,
        stroked_price: formData.stroked_price,
        duration: formData.duration,
        digitalProductId: +formData.product.digitalProductId,
        hardcoverProductId: +formData.product.hardcoverProductId,
      };

      if (type !== "Edit") {
        await createCourse(payload);
        notify("Book Created Successfully", "success");
      } else {
        await editCourse(bookId?.toString() as string, payload);
        notify("Book Updated Successfully", "success");
      }

      handleCancel();
    } catch (error: any) {
      notify(formateErrorObject(error), "error");
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleSelectChange = ({
    value,
    key,
    name,
  }: {
    value: string;
    key?: string;
    name: string;
  }) => {
    if (name === "author") {
      setFormData({
        ...formData,
        author: {
          name: key as string,
          value: value,
        },
      });
    } else if (name === "products") {
      const product = products?.filter(
        (product) => product.productId === value
      );
      if (product?.length) {
        setFormData({
          ...formData,
          product: {
            productId: product[0]?.productId as string,
            slug: product[0]?.slug as string,
            hardcoverProductId: product[0]?.productId as string,
            digitalProductId: product[0]?.productId as string,
          },
          description: product[0]?.description as string,
          title: product[0]?.name as string,
          slug: product[0]?.slug as string,
          stroked_price: product[0]?.stroked_price as number,
          main_price: product[0]?.main_price as number,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAllAuthors = async () => {
    try {
      const authors = await getAllAuthors();

      setAuthors(
        authors?.data?.map((author: any) => ({
          value: author?._id,
          label: author?.name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleAllCategories = async () => {
    try {
      const categories = await getAllCategories();
      setCategories(
        categories?.data?.map((category: any) => ({
          value: category?._id,
          label: category?.name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const environment = process.env.REACT_APP_PUBLIC_APP_ENV;

  const handleAllProducts = async (type: string) => {
    const isLong = type === "long";

    try {
      const filter = isLong
        ? { book_type: "hardcover", category_id: 6 }
        : type === "comingSoon"
        ? { book_type: "digital", category_id: 6 }
        : type === "course"
        ? { sub_category_id: 5 }
        : { sub_category_id: environment === "production" ? 14 : 13 };

      const products = await getAllProducts(filter);
      setProducts(
        products?.data?.map((product: any) => ({
          productId: isLong ? product?.digital_pair?.id : product?.id,
          hardcoverProductId: isLong ? product?.id : product?.id,
          digitalProductId: isLong ? product?.digital_pair?.id : product?.id,
          slug: product?.slug,
          name: product?.name,
          description: product?.description,
          externalLink: product?.external_link,
          main_price: product?.main_price,
          stroked_price: product?.stroked_price,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  useEffect(() => {
    if (formData.type && formData.type !== "") {
      handleAllProducts(formData.type);
    }
  }, [formData.type]);

  useEffect(() => {
    if (visible) {
      handleAllAuthors();
      handleAllCategories();
    }
  }, [visible]);

  const handleInputChange = (
    name: string,
    value:
      | string
      | File
      | undefined
      | null
      | []
      | { page: number; start: string; end: string }[]
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSingleBook = async (bookId: string) => {
    try {
      //   setLoading(true);
      const response = await getSingleBook(bookId);
      const data = response.data;
      const product = products?.filter(
        (product) => product.productId === data.productId
      );
      setFormData({
        ...formData,
        coverImage: generateUrlForImage(
          `${baseUrl}/`,
          `${Endpoints.getCoverImagesForBooks}/`,
          `${data?._id}`
        ),
        description: data?.description,
        type: data?.type,
        isExternalLink: data?.isExternalLink,
        category_id: data?.categories?.length && data?.categories[0]?._id,
        title: data?.title,
        slug: data?.slug,
        featured: data?.featured,
        author: {
          name: data?.author_list?.[0]?.name,
          value: data?.author_list?.[0]?._id,
        },
        isListed: data?.isListed,
        product: {
          slug: product?.length ? (product[0]?.slug as string) : "",
          digitalProductId: data?.digitalProductId,
          productId: data?.productId,
          hardcoverProductId: data?.hardcoverProductId,
        },
        main_price: product?.length && product[0]?.main_price,
        stroked_price: product?.length && product[0]?.stroked_price,

        duration: data?.duration,
      });
      const hours = Math.floor(data.duration / 3600);
      const minutes = Math.floor((data.duration % 3600) / 60);
      setTime({ hours, minutes });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    if (bookId) {
      handleSingleBook(bookId.toString());
    }
  }, [bookId]);

  return (
    <Modal
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      destroyOnClose
      footer={false}
      className="customLoginModal bg-bgColor bg-opacity-70  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="flex items-center flex-col justify-center px-5 md:px-10">
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className="pb-3 flex flex-col gap-y-2 ">
            <h1 className="text-xl flex justify-center gap-x-2 font-medium lg:font-normal lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
              {type} {isCourse ? "Course" : "Book"}
            </h1>
          </div>

          <form className="flex flex-col mt-2 gap-y-3 h-full  w-full ">
            <div className="flex max-w-full gap-x-2 h-full">
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                <Select
                  style={{ height: 40 }}
                  value={formData.type || undefined}
                  placeholder="Select Book Type"
                  onChange={(value) =>
                    handleSelectChange({
                      value,
                      name: "type",
                    })
                  }
                  className="rounded-xl border border-bgDarkGray  antdSelect "
                  options={[
                    {
                      value: "long",
                      label: "Long Books",
                    },
                    {
                      value: "short",
                      label: "Short Books",
                    },
                    {
                      value: "comingSoon",
                      label: "Coming Soon",
                    },
                  ]}
                />
                {errors.type && (
                  <p className="text-red-500 text-xs">{errors.type}</p>
                )}
              </div>
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                <Select
                  showSearch
                  style={{ height: 40 }}
                  value={formData?.product?.productId || undefined}
                  placeholder="Select Product Type"
                  filterOption={(input, option) =>
                    (option?.name ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.name ?? "")?.toLowerCase())
                  }
                  onChange={(value) =>
                    handleSelectChange({
                      value,
                      name: "products",
                    })
                  }
                  // disabled={}
                  className="rounded-xl border border-bgDarkGray  antdSelect "
                  options={products?.map((product) => ({
                    value: product?.productId,
                    label: product?.name,
                    name: product?.name?.toLowerCase(),
                  }))}
                />
                {errors.product && (
                  <p className="text-red-500 text-xs">{errors.product}</p>
                )}
              </div>
            </div>

            <div className="flex w-full gap-x-2">
              <div className="col-span-1 w-full flex gap-y-.5">
                <Input
                  type="text"
                  name="title"
                  autoComplete="off"
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData.title}
                  placeholder="Title"
                  onChange={handleChange}
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errors.title && (
                  <p className="text-red-500 text-xs">{errors.title}</p>
                )}
              </div>

              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <span className="border-bgDarkGray justify-between p-2 border rounded-lg h-full flex items-center">
                  <p className="font-semibold">
                    {!formData.featured ? "Set As Featured" : "Featured"}
                  </p>
                  <Switch
                    checked={formData.featured}
                    onChange={(checked) =>
                      setFormData({
                        ...formData,
                        featured: checked,
                      })
                    }
                    className="bg-lightgray"
                  />
                </span>

                {errors.featured && (
                  <p className="text-red-500 text-xs">{errors.featured}</p>
                )}
              </div>
            </div>
            <div className="col-span-1 flex-col flex gap-y-.5 w-full">
              <Select
                showSearch
                style={{ height: 40 }}
                placeholder="Select Author"
                filterOption={(input, option) =>
                  (option?.name ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.name ?? "")
                    ?.toLowerCase()
                    ?.localeCompare((optionB?.name ?? "")?.toLowerCase())
                }
                value={formData.author.name || undefined}
                onChange={(value, option) =>
                  handleSelectChange({
                    value,
                    key: (option as { value: string; label: string })
                      ?.label as string,
                    name: "author",
                  })
                }
                className="rounded-xl border border-bgDarkGray  antdSelect "
                options={authors?.map((product) => ({
                  value: product?.value,
                  label: product?.label,
                  name: product?.label?.toLowerCase(),
                }))}
              />

              {errors.author && (
                <p className="text-red-500 text-xs">{errors.author}</p>
              )}
            </div>
            <div className="flex w-full gap-x-2">
              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <Select
                  style={{ height: 40 }}
                  showSearch
                  value={formData.category_id || undefined}
                  placeholder="Select Category"
                  onChange={(value) =>
                    handleSelectChange({
                      value,
                      name: "category_id",
                    })
                  }
                  filterOption={(input, option) =>
                    (option?.name ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      ?.toLowerCase()
                      ?.localeCompare((optionB?.name ?? "")?.toLowerCase())
                  }
                  className="rounded-xl border border-bgDarkGray  antdSelect "
                  dropdownRender={(menu) => <>{menu}</>}
                  options={categories?.map((product) => ({
                    value: product?.value,
                    label: product?.label,
                    name: product?.label?.toLowerCase(),
                  }))}
                />
                {errors.category_id && (
                  <p className="text-red-500 text-xs">{errors.category_id}</p>
                )}
              </div>
              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <span className="border-bgDarkGray justify-between p-2 border rounded-lg h-full flex items-center">
                  <p className="font-semibold">
                    {!formData.isListed ? "Un-List" : "Listed"}
                  </p>
                  <Switch
                    checked={formData.isListed}
                    onChange={(checked) =>
                      setFormData({
                        ...formData,
                        isListed: checked,
                      })
                    }
                    className="bg-lightgray"
                  />
                </span>

                {errors.isListed && (
                  <p className="text-red-500 text-xs">{errors.isListed}</p>
                )}
              </div>
            </div>
            <div className="flex w-full gap-x-2">
              <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                <Input
                  type="text"
                  name="slug"
                  autoComplete="off"
                  style={{
                    backgroundColor: "#080808",
                  }}
                  value={formData.slug}
                  placeholder="slug"
                  onChange={handleChange}
                  size="large"
                  className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
                />
                {errors.slug && (
                  <p className="text-red-500 text-xs">{errors.slug}</p>
                )}
              </div>
              <ShouldRender check={formData?.type === "long"}>
                <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                  <span className="border-bgDarkGray justify-between p-2 border rounded-lg h-full flex items-center">
                    <p className="font-semibold">
                      {!formData.isExternalLink
                        ? "WebFlow-Page"
                        : "Landing-Page"}
                    </p>
                    <Switch
                      checked={formData.isExternalLink}
                      onChange={(checked) =>
                        setFormData({
                          ...formData,
                          isExternalLink: checked,
                        })
                      }
                      className="bg-lightgray"
                    />
                  </span>

                  {errors.isExternalLink && (
                    <p className="text-red-500 text-xs">
                      {errors.isExternalLink}
                    </p>
                  )}
                </div>
              </ShouldRender>
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="text"
                name="description"
                autoComplete="off"
                style={{
                  backgroundColor: "#080808",
                }}
                value={formData.description}
                placeholder="Description"
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.description && (
                <p className="text-red-500 text-xs">{errors.description}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5 w-full">
              <UploadFile
                placeholder="Click or drag to upload a cover image (WEBP, max 1MB)"
                accept=".webp"
                image={formData.coverImage ? formData.coverImage : null}
                onFileChange={(file) => handleInputChange("coverImage", file)}
                onImageRemove={() => handleInputChange("coverImage", null)}
                isSession={true}
                maxFileSize={1 * 1024 * 1024}
              />
              {errors.coverImage && (
                <p className="text-red-500 text-xs">{errors.coverImage}</p>
              )}
            </div>
            <div className=" w-full gap-y-1">
              <span className="text-white mb-2">Total Duration</span>
              <div className="flex gap-2 justify-between w-full mt-2">
                {/* Hours Input */}

                <div className="flex items-center justify-center gap-x-3">
                  <InputNumber
                    min={0}
                    max={23}
                    value={
                      time.hours !== null && time.hours !== undefined
                        ? time.hours
                        : undefined
                    }
                    placeholder="Hours"
                    onChange={(value) => handleTimeChange(value, "hours")}
                    size="small"
                    className="w-full border text-sm rounded-xl border-bgDarkGray py-3 text-white bg-bgColor customInputBlack"
                  />
                  <span className="text-white">hr</span>
                </div>
                {/* Minutes Input */}
                <div className="flex items-center justify-center gap-x-3">
                  <InputNumber
                    min={0}
                    max={59}
                    value={
                      time.minutes !== null && time.minutes !== undefined
                        ? time.minutes
                        : undefined
                    }
                    placeholder="Minutes"
                    onChange={(value) => handleTimeChange(value, "minutes")}
                    size="small"
                    className="w-full border text-sm rounded-xl border-bgDarkGray py-3 text-white bg-bgColor customInputBlack"
                  />
                  <span className="text-white">min</span>
                </div>
              </div>

              {errors.duration && (
                <p className="text-red-500 text-xs">{errors.duration}</p>
              )}
            </div>
            <Divider />

            <span className="flex justify-center gap-x-2 mt-2 pb-4">
              <ButtonDefault
                size={4}
                variant={ButtonVariants.GRAY}
                className="p-1 w-1/3"
                rounded={true}
                onClick={() => handleCancel()}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  Cancel
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className="p-1 w-2/3"
                disabled={confirmLoading}
                loading={confirmLoading}
                rounded={true}
                onClick={handleSubmit}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  {type} {isCourse ? "Course" : "Book"}
                </span>
              </ButtonDefault>
            </span>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateCourseModal;
