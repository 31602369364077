import React from "react";
import { CustomIconProps } from "../../utils/interfaces/user/icons";

const LandingPageSideBarIcons: React.FC<
  CustomIconProps & { outlined?: boolean }
> = ({ width, height, fill, outlined, ...rest }) => {
  return (
    <svg
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill={outlined ? "none" : fill}
      stroke={outlined ? fill : "none"}
      strokeWidth={outlined ? "1.5" : "0"}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_4864_78850)">
        <path
          d="M22 4H2V20H22V4ZM4 9H14.5V12.5H4V9ZM4 14.5H14.5V18H4V14.5ZM20 18H16.5V9H20V18Z"
          fill={outlined ? "none" : fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4864_78850">
          <rect width="24" height="24" fill={outlined ? "none" : fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LandingPageSideBarIcons;
