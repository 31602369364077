import React, { useEffect, useState } from "react";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import clsx from "clsx";
import Parser from "../../shared/Parser";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  MenuProps,
  Radio,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { notify } from "../../shared/basic/notify";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import { getAllReviews } from "../../../services/Review";
import { formateErrorObject } from "../../shared/formateErrorObject";
import CompleteBookTimestampModal from "../CompleteBookTimestampModal";
import SEOModal from "../../BookLandingPageComp/SEOModal";

interface IProductCard {
  data: {
    image: string;
    title: number;
    id: number;
    desc?: string;
    externalLink?: string;
    slug?: string;
    digitalProductId?: number;
    hardProductId?: number;
    seo?: ISEO;
    completeAudioBookTimeStamp: {
      _id: string;
      start: string;
      end: string;
      page: number;
    }[];
  };
  isCourse?: boolean;
  bookType: string;

  setVisibleBook: React.Dispatch<
    React.SetStateAction<{ type: string; value: boolean; bookId?: number }>
  >;
}

export interface ISEO {
  title?: string;
  description?: string;
  keywords?: string;
  canonicalUrl?: string;
  openGraph?: {
    title?: string;
    description?: string;
    image?: string | File;
  };
}

const ProductCard: React.FC<IProductCard> = ({
  data,
  isCourse = false,
  setVisibleBook,
  bookType,
}) => {
  const { image, title, desc, hardProductId, digitalProductId } = data;
  const router = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [couponCodeValue, setCouponCodeValue] = useState<string>();
  const [reviewCount, setReviewCount] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const [isSeoModalOpen, setIsSeoModalOpen] = useState<{
    value: boolean;
    pageId: string | undefined;
  }>({
    value: false,
    pageId: undefined,
  });

  const fetchReviewData = async () => {
    try {
      const response = await getAllReviews({
        page: 1,
        limit: 999999,
        product_id: data?.id?.toString(),
      });
      setReviewCount(response.meta.totalRecords);
    } catch (error: any) {
      notify(formateErrorObject(error), "error");
    }
  };

  useEffect(() => {
    if (data?.id && bookType === "long") {
      fetchReviewData();
    }
  }, [data]);

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: boolean;
  }>({
    buy_now: false,
    cp: false,
  });

  const [mode, setMode] = useState<string>("hard");

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  const handleCheckboxChange = (key: string, checked: boolean) => {
    setSelectedItems((prev) => ({
      ...prev,
      [key]: checked,
    }));
  };

  const handleModeChange = (e: any) => {
    setMode(e.target.value);
  };

  const handleCopyURL = () => {
    const selectedParams = Object.entries(selectedItems)
      .filter(([, isSelected]) => isSelected)
      .map(([key]) => {
        if (key === "buy_now") {
          return "buy_now=true";
        } else if (key === "cp") {
          return `cp=${couponCodeValue ?? "XXXX"}`;
        } else {
          return key;
        }
      })
      .join("&");

    const productType =
      mode === "digital"
        ? `add-to-cart=${digitalProductId}`
        : `add-to-cart=${hardProductId}`;
    const defaultMode = process.env.REACT_APP_PUBLIC_APP_ENV;
    const url =
      defaultMode?.toString() === "production"
        ? "https://zebralearn.com"
        : "https://staging.zebralearn.com";
    const staticURL = `${url}/shop/?${productType}&${selectedParams}`;

    navigator.clipboard.writeText(staticURL);
    setDropdownVisible(false);
    setCouponCodeValue(undefined);
    setSelectedItems({
      buy_now: false,
      cp: false,
    });
    notify("Link Copied Successfully", "success");
  };

  const handleOpenSeoModal = (value: string) =>
    setIsSeoModalOpen({ value: true, pageId: value });
  const handleCloseSeoModal = () =>
    setIsSeoModalOpen({ value: false, pageId: undefined });

  const handleCouponCodeInput = (value: string) => {
    if (value.trim()) {
      handleCheckboxChange("cp", true);
    }
    setCouponCodeValue(value);
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="no-select"
        >
          <Checkbox
            checked={selectedItems.buy_now}
            onChange={(e) => handleCheckboxChange("buy_now", e.target.checked)}
          >
            Buy Now ( 2nd step )
          </Checkbox>
        </span>
      ),
    },
    {
      key: "2",
      label: (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="no-select"
        >
          <Checkbox
            checked={selectedItems.cp}
            onChange={(e) => handleCheckboxChange("cp", e.target.checked)}
          >
            <Input
              placeholder="Coupon Code"
              value={couponCodeValue}
              onChange={(e) => handleCouponCodeInput(e.target.value)}
            />
          </Checkbox>
        </span>
      ),
    },
  ];

  return (
    <div className="flex flex-col md:flex-row justify-between border-b gap-x-5 border-b-bgLightGray">
      <span className="flex gap-x-4 py-5 w-full">
        <img
          src={image}
          className={clsx(
            !isCourse ? "w-28 h-36 rounded-sm" : "w-52 h-28 rounded-lg"
          )}
        />
        <div className="flex gap-y-2  w-full">
          <span className="lg:py-6  w-full">
            <p className="font-semibold text-xl">{title}</p>
            <p className=" lg:w-1/2 text-textDarkGrayColor  ">
              <Parser
                maxWords={20}
                text={desc?.replace(/<[^>]*>?/gm, "") || ""}
              />
            </p>
          </span>
        </div>
      </span>
      <div className="flex justify-end items-center gap-y-2">
        <span className="flex flex-wrap  gap-3 max-w-[90%]">
          <ShouldRender check={!isCourse}>
            <ButtonDefault
              size={0}
              variant={ButtonVariants.UNSTYLED}
              enableScaling={false}
              className=" w-fit bg-transparent border text-base rounded-lg font-semibold border-white text-white 
             transition-all duration-300 ease-in-out hover:bg-white hover:text-black"
              onClick={() => {
                router(`/products/${data?.id}`);
              }}
            >
              <span
                rel="noreferrer"
                className="px-5 text-base py-1.5 whitespace-nowrap"
              >
                Chapters
              </span>
            </ButtonDefault>
          </ShouldRender>

          <ButtonDefault
            size={0}
            variant={ButtonVariants.UNSTYLED}
            enableScaling={false}
            className=" w-fit bg-transparent border text-base rounded-lg font-semibold border-white text-white 
             transition-all duration-300 ease-in-out hover:bg-white hover:text-black"
            onClick={() =>
              setVisibleBook({ type: "Edit", value: true, bookId: data.id })
            }
          >
            {" "}
            <span className="px-5 text-base py-1.5 whitespace-nowrap">
              Edit {isCourse ? "Course" : "Book"}
            </span>
          </ButtonDefault>
          <ButtonDefault
            size={0}
            variant={ButtonVariants.UNSTYLED}
            enableScaling={false}
            className=" w-fit bg-transparent border rounded-lg font-semibold border-white text-white 
            transition-all duration-300 ease-in-out hover:bg-white hover:text-black"
            onClick={() => {
              handleOpenSeoModal(data?.id?.toString());
            }}
          >
            <span className="px-5 text-base py-1.5 whitespace-nowrap">
              Set SEO
            </span>
          </ButtonDefault>
          <ShouldRender check={!isCourse}>
            <ButtonDefault
              size={0}
              variant={ButtonVariants.UNSTYLED}
              enableScaling={false}
              className=" w-fit bg-transparent border rounded-lg font-semibold border-white text-white 
              transition-all duration-300 ease-in-out hover:bg-white hover:text-black"
              onClick={() => setModalOpen(true)}
            >
              <span className="px-5 text-base py-1.5 whitespace-nowrap">
                AudioBook Timestamp
              </span>
            </ButtonDefault>
          </ShouldRender>
          <ShouldRender check={bookType === "long" || isCourse}>
            <ButtonDefault
              size={0}
              variant={ButtonVariants.UNSTYLED}
              enableScaling={false}
              className=" w-fit bg-transparent border text-base rounded-lg font-semibold border-white text-white 
             transition-all duration-300 ease-in-out hover:bg-white hover:text-black"
              onClick={() => {
                router(`/reviews/${data?.id}`);
              }}
            >
              <span
                rel="noreferrer"
                className="px-5 text-base py-1.5 whitespace-nowrap"
              >
                Reviews:{" "}
                <p
                  style={{
                    border: "1px solid #FFFFFF24",
                  }}
                  className="inline-flex bg-[#F48E2F40] text-[10px] px-1 h-fit rounded-md"
                >
                  {reviewCount}
                </p>
              </span>
            </ButtonDefault>
          </ShouldRender>

          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            open={dropdownVisible}
            onOpenChange={(visible) => setDropdownVisible(visible)}
            dropdownRender={(menu) => (
              <div className="bg-bgLightGray p-1 rounded-lg">
                <Radio.Group
                  onChange={handleModeChange}
                  value={mode}
                  className="mb-1 bg-bgLightGray border-bgLightGray text-white flex gap-2 rounded-md overflow-hidden border-2 auth-style"
                >
                  <Radio.Button
                    className={clsx(
                      "w-1/2 border-none rounded-md text-center",
                      mode === "hard"
                        ? "bg-white font-medium"
                        : "bg-transparent text-white "
                    )}
                    value="hard"
                  >
                    Hard Copy
                  </Radio.Button>
                  <Radio.Button
                    className={clsx(
                      "w-1/2 border-none rounded-md text-center",
                      mode === "digital"
                        ? "bg-white text-green-600 font-medium"
                        : "bg-transparent text-white "
                    )}
                    value="digital"
                  >
                    Digital Copy
                  </Radio.Button>
                </Radio.Group>

                {React.cloneElement(menu as React.ReactElement<any>, {
                  style: menuStyle,
                })}
                <Divider
                  style={{
                    margin: "7px 0 7px 0",
                  }}
                />
                <div className="pb-2 px-1">
                  <Button
                    type="primary"
                    className="w-full"
                    icon={<CopyOutlined />}
                    onClick={handleCopyURL}
                  >
                    Copy URL
                  </Button>
                </div>
              </div>
            )}
          >
            <ButtonDefault
              size={0}
              variant={ButtonVariants.UNSTYLED}
              enableScaling={false}
              className=" w-fit bg-transparent border text-base rounded-lg font-semibold border-white text-white 
             transition-all duration-300 ease-in-out hover:bg-white hover:text-black"
              onClick={(e) => e.preventDefault()}
            >
              <span className="px-5 text-base py-1.5 whitespace-nowrap">
                Copy Link
              </span>
            </ButtonDefault>
          </Dropdown>
        </span>

        <CompleteBookTimestampModal
          visible={isModalOpen}
          onClose={() => setModalOpen(false)}
          data={data?.completeAudioBookTimeStamp}
        />
        <SEOModal
          visible={isSeoModalOpen}
          onClose={handleCloseSeoModal}
          type="book"
        />
      </div>
    </div>
  );
};

export default ProductCard;
