import { ISEO } from "../../components/BookLandingPageComp/SEOModal";
import { Endpoints } from "../../network";
import apiClient from "../base";

export const getProductWiseData = async (pageId: string) => {
  const response = await apiClient.get(`${Endpoints.getSEOData}/${pageId}`);

  return response?.data;
};

export const createProductWiseData = async (
  pageType: string,
  pageId: string,
  data: ISEO
): Promise<any> => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === "openGraph" && typeof value === "object" && value !== null) {
      Object.entries(value).forEach(([ogKey, ogValue]) => {
        if (ogKey === "image") {
          if (ogValue instanceof File) {
            formData.append(`ogImage`, ogValue);
          }
        } else if (typeof ogValue === "object" && ogValue !== null) {
          formData.append(`openGraph[${ogKey}]`, JSON.stringify(ogValue));
        } else {
          formData.append(`openGraph[${ogKey}]`, ogValue as string);
        }
      });
    } else if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, item);
      });
    } else if (typeof value === "object" && value !== null) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value as string);
    }
  });

  const response = await apiClient.post(
    `${Endpoints.getSEOData}/${pageType}/${pageId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};
