import React from "react";
import { CustomIconProps } from "../../utils/interfaces/user/icons";

const PersonalityTestSideBarIcon: React.FC<
  CustomIconProps & { outlined?: boolean }
> = ({ width, height, fill, outlined, ...rest }) => {
  return (
    <svg
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill={outlined ? "none" : fill}
      stroke={outlined ? fill : "none"}
      strokeWidth={outlined ? "1.5" : "0"}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 5C22 4.44772 21.5523 4 21 4H3C2.44772 4 2 4.44772 2 5V19C2 19.5523 2.44772 20 3 20H21C21.5523 20 22 19.5523 22 19V5ZM10.715 10C10.715 8.849 9.866 8 8.715 8C7.563 8 6.715 8.849 6.715 10C6.715 11.151 7.564 12 8.715 12C9.866 12 10.715 11.151 10.715 10ZM5 16H12.43V15.535C12.43 14.162 10.754 12.75 8.715 12.75C6.676 12.75 5 14.162 5 15.535V16ZM15 15H19V13H15V15ZM14 11H19V9H14V11Z"
        fill={outlined ? "none" : fill}
      />
    </svg>
  );
};

export default PersonalityTestSideBarIcon;
