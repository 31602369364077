import React from "react";
import { CustomIconProps } from "../../utils/interfaces/user/icons";

const UserSideBarIcon: React.FC<CustomIconProps & { outlined?: boolean }> = ({
  width,
  height,
  fill,
  outlined,
  ...rest
}) => {
  return (
    <svg
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill={outlined ? "none" : fill}
      stroke={outlined ? fill : "none"}
      strokeWidth={outlined ? "1.5" : "0"}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11 14.062V20H13V14.062C16.946 14.554 20 17.92 20 22H4C4.00003 20.0514 4.71119 18.1698 6 16.7083C7.28882 15.2467 9.0667 14.3058 11 14.062ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z"
        fill={outlined ? "none" : fill}
      />
    </svg>
  );
};

export default UserSideBarIcon;
