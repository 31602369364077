import { Divider, Input, Modal, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";

import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { notify } from "../../shared/basic/notify";
import { formateErrorObject } from "../../shared/formateErrorObject";

import {
  createBanner,
  editBanner,
  getSingleBanner,
} from "../../../services/banner";
import ImageUploader from "../../shared/ImageUpload";
import { getAllProducts } from "../../../services/dashboard";

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  type?: string;
  page?: string;
  banner: string;
  setClearImage?: (value: boolean) => void;
  clearImage: boolean;
}

export const generateUrlForImageBanner = (
  baseUrl: string,
  page: string,
  type: string
): string => {
  const url = `${baseUrl}api/banner/images/${page}${type}`;
  return url;
};

export enum PageNames {
  HOME = "home",
  SCHOOL = "school",
  BOOKS = "books",
  LIVE = "live",
  COURSES = "course",
}

const CreateBannerModal: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  type,
  page,
  banner,
  clearImage,
  setClearImage,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState<
    {
      productId: string;
      slug: string;
      name: string;
    }[]
  >();
  const [formData, setFormData] = useState<{
    heading: string;
    link: string;
    largeScreenImage: any;
    mobileScreenImageWeb: any;
    productId: string;
    slug: string;
    mobileScreens: any;
  }>({
    heading: "",
    link: "",
    productId: "",
    slug: "",
    largeScreenImage: undefined,
    mobileScreenImageWeb: undefined,
    mobileScreens: undefined,
  });

  const [errors, setErrors] = useState({
    heading: "",
    link: "",
    productId: "",
    slug: "",
    largeScreenImage: "",
    mobileScreenImageWeb: "",
    mobileScreens: "",
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const handleCancel = () => {
    onCancel();
    setErrors({
      heading: "",
      link: "",
      productId: "",
      slug: "",
      largeScreenImage: "",
      mobileScreenImageWeb: "",
      mobileScreens: "",
    });
    setClearImage && setClearImage(false);
    setFormData({
      heading: "",
      link: "",
      productId: "",
      slug: "",
      largeScreenImage: undefined,
      mobileScreenImageWeb: undefined,

      mobileScreens: undefined,
    });
    if (window !== undefined) {
      window.location.reload();
    }
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      handleCancel();
    }, 2000);
  };

  const handleAllProducts = async () => {
    try {
      setLoading(true);
      const products = await getAllProducts({
        book_type: "hardcover",
        category_id: 6,
      });

      setProducts(
        products?.data?.map((product: any) => ({
          productId: product?.digital_pair?.id,
          slug: product?.slug,
          name: product?.name,
        }))
      );
    } catch (error: any) {
      const message = formateErrorObject(error);
      console.error(message, error);
    } finally {
      setLoading(false);
    }
  };

  const isValidURL = (url: string) => {
    // Basic URL pattern matching regex
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(url);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      heading: "",
      link: "",
      largeScreenImage: "",
      mobileScreenImageWeb: "",
      mobileScreens: "",
      productId: "",
      slug: "",
    };
    if (!formData?.heading && type !== "Edit") {
      validationErrors.heading = "Please Enter heading";
    }
    if (!formData?.link && type !== "Edit") {
      validationErrors.link = "Please Enter link";
    } else if (!isValidURL(formData?.link)) {
      validationErrors.link = "Please enter a valid URL";
    }

    // if (!formData?.largeScreenImage && type !== "Edit") {
    //   validationErrors.largeScreenImage =
    //     "Please enter web large Screen Image ";
    // }

    // if (!formData?.mobileScreenImageWeb && type !== "Edit") {
    //   validationErrors.largeScreenImage =
    //     "Please enter web mobile screen Image ";
    // }

    // if (!formData?.mobileScreens && type !== "Edit") {
    //   validationErrors.mobileScreens =
    //     "Please enter mobile mobile screen Image ";
    // }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setConfirmLoading(true);
        if (type !== "Edit") {
          await createBanner({
            data: {
              heading: formData?.heading,
              link: formData.link,
              ...(formData.productId && {
                productId: formData.productId,
              }),
              ...(formData.slug && {
                productId: formData.slug,
              }),
              ...(typeof formData.largeScreenImage !== "undefined" && {
                webLargeScreenImage: formData.largeScreenImage,
              }),
              ...(typeof formData.mobileScreenImageWeb !== "undefined" && {
                webMobileScreenImage: formData.mobileScreenImageWeb,
              }),
              ...(typeof formData.mobileScreens !== "undefined" && {
                mobileScreenImage: formData.mobileScreens,
              }),
            },
            pageId: page as string,
          });
          notify("Banner Created Successfully", "success");
        } else {
          await editBanner({
            pageId: page as string,
            data: {
              heading: formData.heading,
              link: formData.link,
              ...(formData.productId && {
                productId: formData.productId,
              }),
              ...(formData.slug && {
                slug: formData.slug,
              }),
              ...(typeof formData.largeScreenImage !== "string" && {
                webLargeScreenImage: formData.largeScreenImage,
              }),
              ...(typeof formData.mobileScreenImageWeb !== "string" && {
                webMobileScreenImage: formData.mobileScreenImageWeb,
              }),
              ...(typeof formData.mobileScreens !== "string" && {
                mobileScreenImage: formData.mobileScreens,
              }),
            },
            bannerId: banner as string,
          });
          notify("Banner updated Successfully", "success");
        }

        handleCancel();
      } catch (error: any) {
        const message = formateErrorObject(error);
        notify(message, "error");
      } finally {
        setConfirmLoading(false);
      }
    }
  };
  // const handleInputChange = (
  //   name: string,
  //   value: string | File | undefined
  // ) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };

  const handleSingleBanner = async (banner: string) => {
    try {
      //   setLoading(true);
      const response = await getSingleBanner({ banner });
      const data = response?.data;

      setFormData({
        heading: data?.heading,
        link: data?.link,
        ...(data?.productId && {
          productId: data?.productId,
        }),
        ...(data?.slug && {
          slug: data?.slug,
        }),

        ...(data?.web?.largeScreenImage && {
          largeScreenImage: generateUrlForImageBanner(
            `${baseUrl}/`,
            `${banner}/`,
            `webLarge`
          ),
        }),
        ...(data?.mobile?.mobileScreens && {
          mobileScreens: generateUrlForImageBanner(
            `${baseUrl}/`,
            `${banner}/`,
            `mobile`
          ),
        }),
        ...(data?.web?.mobileScreenImage && {
          mobileScreenImageWeb: generateUrlForImageBanner(
            `${baseUrl}/`,
            `${banner}/`,
            `webMobile`
          ),
        }),
      });
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, "error");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    if (banner) handleSingleBanner(banner);
    if (visible) handleAllProducts();
  }, [banner, visible]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleImageUpload = (file: File | null) => {
    setFormData({ ...formData, largeScreenImage: file as File });
  };

  const handleImageUploadMobileScreenImage = (file: File | null) => {
    setFormData({ ...formData, mobileScreenImageWeb: file as File });
  };
  const handleImageUploadMobileScreens = (file: File | null) => {
    setFormData({ ...formData, mobileScreens: file as File });
  };

  const handleSelectChange = ({ value }: { value: string }) => {
    const product = products?.filter((product) => product.productId === value);
    if (product?.length) {
      setFormData({
        ...formData,
        productId: product[0]?.productId as string,
        slug: product[0]?.slug as string,
      });
    }
  };
  const strToBool = (value: string) => value?.toLowerCase() === "true";

  return (
    <Modal
      title=""
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={false}
      className="customLoginModal bg-bgColor bg-opacity-70  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="flex items-center flex-col justify-center px-5 md:px-10">
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className="pb-3 flex flex-col gap-y-2 ">
            <h1 className="text-xl flex justify-center gap-x-2 font-medium lg:font-normal lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
              {type} Banner
            </h1>
          </div>

          <form className="flex flex-col mt-2 gap-y-3 w-full ">
            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="text"
                name="heading"
                placeholder="Heading"
                style={{
                  backgroundColor: "#141414",
                }}
                autoComplete="off"
                value={formData.heading}
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.heading && (
                <p className="text-red-500">{errors.heading}</p>
              )}
            </div>

            <div className="col-span-1 flex-col flex gap-y-.5">
              <Input
                type="text"
                name="link"
                placeholder="Link"
                style={{
                  backgroundColor: "#141414",
                }}
                autoComplete="off"
                value={formData.link}
                onChange={handleChange}
                size="large"
                className="border text-sm focus-within:bg-bgColor  placeholder:text-textDarkGrayColor rounded-xl border-bgDarkGray  py-3  text-white bg-bgColor customInputBlack makeBgBlack"
              />
              {errors.link && <p className="text-red-500">{errors.link}</p>}
            </div>
            <div className="flex gap-x-3">
              <div className=" flex-col flex gap-y-.5 w-1/2">
                <Select
                  showSearch
                  loading={loading}
                  style={{ width: "100%", height: 40 }}
                  value={formData?.productId || undefined}
                  placeholder="Select Product Type"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      ?.toLowerCase()
                      .localeCompare((optionB?.label ?? "")?.toLowerCase())
                  }
                  onChange={(value) =>
                    handleSelectChange({
                      value,
                    })
                  }
                  className="rounded-xl border border-bgDarkGray w-full   "
                  options={products?.map((product) => ({
                    value: product?.productId,
                    label: product?.name,
                  }))}
                />
              </div>
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                <span className="border-bgDarkGray justify-between p-2 border rounded-lg h-full flex items-center">
                  <p className="font-semibold">
                    {!formData.slug ? "Show Button" : "Hide Button"}
                  </p>
                  <Switch
                    checked={strToBool(formData.slug)}
                    onChange={(checked) =>
                      setFormData({
                        ...formData,
                        slug: checked?.toString(),
                      })
                    }
                    className="bg-lightgray"
                  />
                </span>

                {errors.slug && (
                  <p className="text-red-500 text-xs">{errors.slug}</p>
                )}
              </div>
            </div>

            <div className="text-xl">Web</div>
            <div className="flex w-full gap-x-2">
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                {/* <UploadFile
                  placeholder="Click or drag to upload  cover Image"
                  image={
                    formData.largeScreenImage ? formData.largeScreenImage : null
                  }
                  onFileChange={(file) =>
                    handleInputChange("largeScreenImage", file)
                  }
                  onImageRemove={() =>
                    handleInputChange("largeScreenImage", undefined)
                  }
                  isSession={true}
                /> */}
                <ImageUploader
                  uploadAction="Web Large image"
                  maxFileCount={1}
                  updateText={
                    "Drag and drop files here or click to Web Desktop upload (1340*440)"
                  }
                  onChange={handleImageUpload}
                  defaultImage={
                    typeof formData.largeScreenImage === "string"
                      ? formData.largeScreenImage
                      : ""
                  }
                  clearImage={clearImage}
                />
                {errors.largeScreenImage && (
                  <p className="text-red-500 text-xs">
                    {errors.largeScreenImage}
                  </p>
                )}
              </div>
              <div className="col-span-1 flex-col flex gap-y-.5 w-1/2">
                <ImageUploader
                  uploadAction="Web Large image"
                  updateText={
                    "Drag and drop files here or click to  Web mobile upload (same as web)"
                  }
                  maxFileCount={1}
                  onChange={handleImageUploadMobileScreenImage}
                  defaultImage={
                    typeof formData.mobileScreenImageWeb === "string"
                      ? formData.mobileScreenImageWeb
                      : ""
                  }
                  clearImage={clearImage}
                />
                {errors.mobileScreenImageWeb && (
                  <p className="text-red-500 text-xs">
                    {errors.mobileScreenImageWeb}
                  </p>
                )}
              </div>
            </div>
            <Divider />
            <div className="text-xl">App</div>
            <div className="col-span-1 flex-col flex gap-y-.5 w-full">
              <ImageUploader
                updateText={
                  "Drag and drop files here or click to App Image upload (same as web)"
                }
                uploadAction="Web Large image"
                maxFileCount={1}
                onChange={handleImageUploadMobileScreens}
                defaultImage={
                  typeof formData.mobileScreens === "string"
                    ? formData.mobileScreens
                    : ""
                }
                clearImage={clearImage}
              />
              {errors.mobileScreens && (
                <p className="text-red-500 text-xs">{errors.mobileScreens}</p>
              )}
            </div>

            <span className="flex justify-center gap-x-2 mt-2 pb-4">
              <ButtonDefault
                size={4}
                variant={ButtonVariants.GRAY}
                className="p-1 w-1/3"
                // disabled={confirmLoading}
                // loading={confirmLoading}
                rounded={true}
                onClick={() => handleCancel()}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  Cancel
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className="p-1 w-2/3"
                disabled={confirmLoading}
                loading={confirmLoading}
                rounded={true}
                onClick={handleSubmit}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  {type} Banner
                </span>
              </ButtonDefault>
            </span>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBannerModal;
