import { ISEO } from "../../components/BookLandingPageComp/SEOModal";
import { Endpoints } from "../../network";
import apiClient from "../base";

export interface ILandingPage {
  authorName: string;
  form: any;
  isUpcoming?: boolean;
  name: string;
  status: string;
  productSlug: string;
}

export const getAllLandingPage = async (
  activeKey: "long" | "short" | "course"
) => {
  const response = await apiClient.get(
    `${Endpoints.getAllLandingPage}?bookType=${activeKey}`
  );

  return response?.data;
};

export const uploadImage = async (data: any): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.uploadImage}`, data);

  console.log(response, "responseAll");
  return response;
};

export const createLandingPage = async (data: ILandingPage): Promise<any> => {
  const response = await apiClient.post(`${Endpoints.createLandingPage}`, {
    ...data,
  });

  return response;
};

export const createShortBookLandingPage = async (
  data: ILandingPage
): Promise<any> => {
  const response = await apiClient.post(
    `${Endpoints.createShortBookLandingPage}`,
    {
      ...data,
    }
  );

  return response;
};

export const updateShortBookLandingPage = async (
  data: ILandingPage,
  pageId: string
): Promise<any> => {
  const response = await apiClient.patch(
    `${Endpoints.createShortBookLandingPage}/${pageId}`,
    {
      ...data,
    }
  );

  return response;
};

// Course

export const createCourseLandingPage = async (
  data: ILandingPage
): Promise<any> => {
  const response = await apiClient.post(
    `${Endpoints.createCourseLandingPage}`,
    {
      ...data,
    }
  );

  return response;
};

export const updateCourseLandingPage = async (
  data: ILandingPage,
  pageId: string
): Promise<any> => {
  const response = await apiClient.patch(
    `${Endpoints.createCourseLandingPage}/${pageId}`,
    {
      ...data,
    }
  );

  return response;
};

export const updateBookSEO = async (id: string, data: ISEO): Promise<any> => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (key === "openGraph" && typeof value === "object" && value !== null) {
      Object.entries(value).forEach(([ogKey, ogValue]) => {
        if (ogKey === "image") {
          if (ogValue instanceof File) {
            formData.append(`ogImage`, ogValue);
          }
        } else if (typeof ogValue === "object" && ogValue !== null) {
          formData.append(`openGraph[${ogKey}]`, JSON.stringify(ogValue));
        } else {
          formData.append(`openGraph[${ogKey}]`, ogValue as string);
        }
      });
    } else if (value instanceof File) {
      formData.append(key, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`${key}[${index}]`, item);
      });
    } else if (typeof value === "object" && value !== null) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value as string);
    }
  });

  const response = await apiClient.patch(
    `${Endpoints.updateBookSeo}/${id}/seo`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

export const updateLandingPage = async (
  data: ILandingPage,
  pageId: string
): Promise<any> => {
  const response = await apiClient.patch(
    `${Endpoints.createLandingPage}/${pageId}`,
    {
      ...data,
    }
  );

  return response;
};

export const getSingleLandingPage = async (id: string): Promise<any> => {
  const response = await apiClient.get(`${Endpoints.getAllLandingPage}/${id}`);

  return response?.data;
};

export const updateLandingPageStatus = async (
  id: string,
  status: string
): Promise<any> => {
  const response = await apiClient.patch(
    `${Endpoints.updateLandingPageStatus}/${id}/status`,
    {
      status,
    }
  );

  return response?.data;
};

export const deleteLandingPage = async (pageId: string) => {
  const response = await apiClient.delete(
    `${Endpoints.getAllLandingPage}/${pageId}`
  );

  return response?.data;
};
