import axios, { AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import { checkSessionExists, setTokenInCookies } from "../config/appwrite";

const baseUrl = process.env.REACT_APP_BASE_URL;

const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 300000,
});

apiClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const authToken = Cookies.get("auth_token");
  if (!authToken && config.headers) {
    const session = await checkSessionExists();
    if (session) {
      const token = await setTokenInCookies();
      config.headers["Authorization"] = `Bearer ${token}`;
    }
  } else if (authToken && config.headers) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
  }

  return config;
});

export default apiClient;
