import React from "react";
import { CustomIconProps } from "../../utils/interfaces/user/icons";

const CategorySidebarIcon: React.FC<
  CustomIconProps & { outlined?: boolean }
> = ({ width, height, fill, outlined, ...rest }) => {
  return (
    <svg
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill={outlined ? "none" : fill}
      stroke={outlined ? fill : "none"}
      strokeWidth={outlined ? "1.5" : "0"}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 3H11V11H3V3ZM3 13H11V21H3V13ZM13 3H21V11H13V3Z"
        fill={outlined ? "none" : fill}
      />
      <path
        d="M13 17C13 19.2091 14.7909 21 17 21C19.2091 21 21 19.2091 21 17C21 14.7909 19.2091 13 17 13C14.7909 13 13 14.7909 13 17Z"
        fill={outlined ? "none" : fill}
      />
    </svg>
  );
};

export default CategorySidebarIcon;
