import React, { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Button,
  Form,
  Upload,
  message,
  UploadProps,
  UploadFile,
} from "antd";
import ImgCrop from "antd-img-crop";

import {
  createProductWiseData,
  getProductWiseData,
} from "../../../services/SEO";
import extractErrorMessage from "../../shared/basic/formateError";
import { notify } from "../../shared/basic/notify";

export interface ISEO {
  title?: string;
  description?: string;
  keywords?: string;
  canonicalUrl?: string;
  openGraph?: {
    title?: string;
    description?: string;
    image?: string | File;
  };
}

interface SEOModalProps {
  visible: {
    value: boolean;
    pageId?: string;
  };
  onClose: () => void;

  type: "book" | "course";
}

const SEOModal: React.FC<SEOModalProps> = ({ visible, onClose, type }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);

  const handleUpload: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList.length > 1) {
      message.error("You can upload only one image.");
      return;
    }

    setFileList(newFileList);

    form.setFieldsValue({
      openGraph: {
        image: newFileList[0]?.originFileObj || newFileList[0]?.url || "",
      },
    });
  };
  const handleGetProductSeoData = async (pageId: string) => {
    try {
      const response = await getProductWiseData(pageId);
      const data = response?.data;
      if (visible.value) {
        form.resetFields();
        form.setFieldsValue(data);
        setFileList(
          data?.openGraph?.image
            ? [{ url: data.openGraph.image, uid: "-1" }]
            : []
        );
      }
      return;
    } catch (error: any) {
      return null;
    }
  };

  useEffect(() => {
    if (visible.value && visible.pageId) {
      handleGetProductSeoData(visible.pageId);
    }
  }, [visible.value, visible.pageId]);
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as File);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleUpdateLandingPageSeo = async (data: ISEO, id: string) => {
    try {
      await createProductWiseData(type, id, data);
      onClose();
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    }
  };

  const handleSubmit = (values: ISEO) => {
    const updatedValues = { ...values };

    if (fileList.length > 0 && fileList[0].originFileObj) {
      updatedValues.openGraph = {
        ...values.openGraph,
        image: fileList[0].originFileObj,
      };
    }

    handleUpdateLandingPageSeo(updatedValues, visible.pageId as string);
  };

  return (
    <Modal
      open={visible.value}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      title="SEO Settings"
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="SEO Title" name="title">
          <Input placeholder="Enter SEO title" />
        </Form.Item>

        <Form.Item label="SEO Description" name="description">
          <Input.TextArea placeholder="Enter SEO description" />
        </Form.Item>

        <Form.Item label="Keywords (comma-separated)" name="keywords">
          <Input placeholder="Enter keywords" />
        </Form.Item>

        <Form.Item label="Canonical URL" name="canonicalUrl">
          <Input placeholder="Enter canonical URL" />
        </Form.Item>

        <div className="font-semibold mt-2">Open Graph</div>

        <Form.Item label="Open Graph Title" name={["openGraph", "title"]}>
          <Input placeholder="Enter OG title" />
        </Form.Item>

        <Form.Item
          label="Open Graph Description"
          name={["openGraph", "description"]}
        >
          <Input placeholder="Enter OG description" />
        </Form.Item>

        <Form.Item name={["openGraph", "image"]} label="Open Graph Image">
          <ImgCrop rotationSlider aspect={1.91}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={handleUpload}
              onPreview={onPreview}
              maxCount={1}
            >
              {fileList.length < 1 && <div>Upload</div>}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <div className="flex w-full gap-2 mt-4">
          <Button
            size="large"
            onClick={() => {
              form.resetFields();
              onClose();
            }}
            className="w-full"
          >
            Cancel
          </Button>
          <Button
            size="large"
            className="w-full"
            type="primary"
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SEOModal;
