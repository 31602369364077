import React from "react";
import { CustomIconProps } from "../../utils/interfaces/user/icons";

const BannerSideBarIcons: React.FC<
  CustomIconProps & { outlined?: boolean }
> = ({ width, height, fill, outlined, ...rest }) => {
  return (
    <svg
      width={width ?? "24"}
      height={height ?? "24"}
      viewBox="0 0 24 24"
      fill={outlined ? "none" : fill}
      stroke={outlined ? fill : "none"}
      strokeWidth={outlined ? "1.5" : "0"}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_4864_78812)">
        <path d="M18 6H6V17H18V6Z" fill={outlined ? "none" : fill} />
        <path d="M4 7H2V16H4V7Z" fill={outlined ? "none" : fill} />
        <path d="M22 7H20V16H22V7Z" fill={outlined ? "none" : fill} />
      </g>
      <defs>
        <clipPath id="clip0_4864_78812">
          <rect width="24" height="24" fill={outlined ? "none" : fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BannerSideBarIcons;
