import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Divider, Affix, InputNumber } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  ICourseLandingPage,
  Section,
} from "../../../utils/interfaces/BookLandingPage";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { getAllAuthors, getAllProducts } from "../../../services/dashboard";
import ImageUploadUrl from "../../shared/ImageUploadUrl";
import { ShouldRender } from "../../shared/basic/ShouldRender";
import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { useNavigate, useParams } from "react-router-dom";
import {
  createCourseLandingPage,
  getSingleLandingPage,
  updateCourseLandingPage,
} from "../../../services/landingPage";
import { notify } from "../../shared/basic/notify";
import extractErrorMessage from "../../shared/basic/formateError";
import { generateUrlForImage } from "../../ProductsComp/Books";
import { Endpoints } from "../../../network";
import CustomLoader from "../../shared/Loading";

type FilteredDataType = Partial<ICourseLandingPage>;

const initialFormData: ICourseLandingPage = {
  authorName: undefined,
  comingSoon: false,
  bookName: "",
  authorImage: "",
  authorDescription: [""],
  shortDescription: "",
  authorDesignation: "",
  companyIcon: "",
  bookImage: "",
  bookIncludes: [{ name: "", value: "" }],
  whatYouWillLearn: [""],
  language: "English",
  productTitle: "",
  productSlug: "",
  productId: "",
  main_price: 0,
  stroked_price: 0,
  authorId: "",
  authorZlUid: "",
  authUser_name: "",
  tags: [""],
  courseDuration: "",
  courseSampleLink: "",
  skills: [""],
  modules: [
    {
      name: "",
      lesson: 1,
      duration: "",
      sections: [{ name: "", duration: "", videoLink: "" }],
    },
  ],
  requirements: [""],
  reviewOverview: "",
  courseLevel: "",
};

const CreateCourseLandingPage: React.FC = () => {
  const [formData, setFormData] = useState<ICourseLandingPage>(initialFormData);
  const [pageId, setPageId] = useState<string>();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [isPublish, setIsPublish] = useState(false);
  const [authors, setAuthors] = useState<
    {
      authorId: string;
      name: string;
      designation: string;
      avatar: any;
      description: string[];
      zl_uid: string;
      user_name: string;
    }[]
  >();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<
    {
      productId: string;
      slug: string;
      name: string;
      description: string;
      externalLink: string;
      bookImage: string;
      main_price: number;
      stroked_price: number;
      global_category: string;
    }[]
  >();

  const router = useNavigate();
  const [form] = Form.useForm();
  const screens = useBreakpoint();
  const { landingPageId } = useParams<{ landingPageId: string }>();

  const handleInputChange = (name: keyof ICourseLandingPage, value: any) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, [name]: value };
      if (localStorage !== undefined && !landingPageId) {
        localStorage.setItem("formData", JSON.stringify(newFormData));
      }

      return newFormData;
    });
  };
  console.log({ formData }, form.getFieldsValue());

  const handleAllProducts = async () => {
    try {
      const products = await getAllProducts({
        sub_category_id: 5,
      });

      setProducts(
        products?.data?.map((product: any) => ({
          productId: product?.id,
          slug: product?.slug,
          name: product?.name,
          description: product?.description,
          externalLink: product?.external_link,
          bookImage: product?.thumbnail_image,
          bookName: product.name,
          main_price: product.main_price,
          stroked_price: product.stroked_price,
          global_category: product.global_category?.name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  const handleAllAuthors = async () => {
    try {
      const authors = await getAllAuthors();
      setAuthors(
        authors?.data?.map((author: any) => ({
          name: author?.name,
          designation: author?.designation?.length
            ? author?.designation[0]
            : "",
          avatar: generateUrlForImage(
            baseUrl as string,
            `${Endpoints.getAuthorAvatar}/`,
            author?._id
          ),
          longDescription: author?.longDescription,
          email: author?.email,
          authorId: author?._id,
          zl_uid: author?.zl_uid,
          user_name: author?.user_name,
        }))
      );
    } catch (error) {
      console.error("API call error:", error);
    }
  };

  useEffect(() => {
    handleAllAuthors();
  }, []);

  const handleModuleChange = (
    moduleIndex: number,
    field: string,
    value: any
  ) => {
    setFormData((prevFormData) => {
      const newModules = prevFormData.modules?.map((module, index) =>
        index === moduleIndex ? { ...module, [field]: value } : module
      );
      return { ...prevFormData, modules: newModules };
    });
  };

  const handleSectionChange = (
    moduleIndex: number,
    sectionIndex: number,
    field: keyof Section,
    value: string
  ) => {
    setFormData((prevFormData) => {
      if (!prevFormData.modules) return prevFormData;

      const newModules = [...prevFormData.modules];
      if (!newModules[moduleIndex]) return prevFormData;

      const newSections = [...newModules[moduleIndex].sections];

      newSections[sectionIndex] = {
        ...newSections[sectionIndex],
        [field]: value,
      } as Section;

      newModules[moduleIndex] = {
        ...newModules[moduleIndex],
        sections: newSections,
      };

      return { ...prevFormData, modules: newModules };
    });
  };

  const addModule = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      modules: [
        ...(prevFormData.modules || []),
        { name: "", lesson: 1, duration: "", sections: [] },
      ],
    }));
  };

  const removeModule = (moduleIndex: number) => {
    setFormData((prevFormData) => {
      if (!prevFormData.modules) return prevFormData;

      const newModules = [...prevFormData.modules];
      newModules.splice(moduleIndex, 1);

      return { ...prevFormData, modules: newModules };
    });
  };

  const addSection = (moduleIndex: number) => {
    setFormData((prevFormData) => {
      if (!prevFormData.modules) return prevFormData;
      const newModules = [...prevFormData.modules];

      if (!newModules[moduleIndex]) return prevFormData;

      newModules[moduleIndex] = {
        ...newModules[moduleIndex],
        sections: [
          ...newModules[moduleIndex].sections,
          { name: "", duration: "", videoLink: "" },
        ],
      };

      return { ...prevFormData, modules: newModules };
    });
  };

  const removeSection = (moduleIndex: number, sectionIndex: number) => {
    setFormData((prevFormData) => {
      if (!prevFormData.modules) return prevFormData;
      const newModules = [...prevFormData.modules];

      if (!newModules[moduleIndex]) return prevFormData;

      const newSections = [...newModules[moduleIndex].sections];
      newSections.splice(sectionIndex, 1);

      newModules[moduleIndex] = {
        ...newModules[moduleIndex],
        sections: newSections,
      };

      return { ...prevFormData, modules: newModules };
    });
  };

  const handleCreateCourseLandingPage = async (body: any) => {
    try {
      setLoading(true);
      if (landingPageId && pageId) {
        await updateCourseLandingPage(body, pageId);
      } else {
        await createCourseLandingPage(body);
      }
      if (localStorage !== undefined && !landingPageId) {
        localStorage.removeItem("formData");
      }

      router("/book-landing-page");
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleGetSingleLandingPage = async (id: string) => {
    try {
      setLoading(true);
      const data = await getSingleLandingPage(id);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...data?.data?.form,
        ...(typeof data?.data?.form?.authorDescription === "string" && {
          authorDescription: [data.data.form.authorDescription],
        }),
      }));
      form.setFieldsValue(data?.data?.form);
      setPageId(data?.data?._id);
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      setLoading(false);
    }
  };

  const filterFormData = (formData: ICourseLandingPage): FilteredDataType => {
    const filteredData: FilteredDataType = {};

    (Object.keys(formData) as (keyof ICourseLandingPage)[]).forEach((key) => {
      if (
        JSON.stringify(formData[key]) !== JSON.stringify(initialFormData[key])
      ) {
        const value = formData[key];

        if (value !== undefined) {
          filteredData[key] = value as any;
        }
      }
    });
    filteredData.language = "English";

    return filteredData;
  };

  const handleSubmit = async (isPublish?: boolean) => {
    const filteredFormData = filterFormData(formData);

    if (isPublish) {
      await form
        .validateFields()
        .then(() => {
          handleCreateCourseLandingPage({
            authorName: formData?.authorId,
            form: filteredFormData,
            name: formData?.productTitle,
            isUpcoming: formData?.comingSoon,
            status: "publish",
            productSlug: formData?.productSlug,
            productId: formData?.productId,
            bookType: "course",
          });
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    } else {
      handleCreateCourseLandingPage({
        authorName: formData?.authorId,
        form: filteredFormData,
        name: formData?.productTitle,
        status: "draft",
        isUpcoming: formData?.comingSoon,
        productSlug: formData?.productSlug,
        productId: formData?.productId,
        bookType: "course",
      });
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedFormData = localStorage.getItem("formData");
      if (storedFormData && !landingPageId) {
        const parsedFormData = JSON.parse(storedFormData);
        setFormData(parsedFormData);
        form.setFieldsValue(parsedFormData);
      }
      if (landingPageId) handleGetSingleLandingPage(landingPageId);
    }
  }, [landingPageId]);

  useEffect(() => {
    handleAllProducts();
    setFormData((prev) => ({ ...prev, language: "English" }));
    form.setFieldsValue({ language: "English" });
  }, []);

  // Course Includes handleChange
  const handleBookIncludesChange = (
    fieldIndex: number,
    field: keyof { name: string; value: string },
    value: string
  ) => {
    setFormData((prevFormData) => {
      const newKeyPoints = prevFormData.bookIncludes.map(
        (additionalField, index) =>
          index === fieldIndex
            ? { ...additionalField, [field]: value }
            : additionalField
      );
      return {
        ...prevFormData,
        bookIncludes: newKeyPoints,
      };
    });
  };

  // Remove Key Points field
  const removeBookIncludes = (index: number) => {
    setFormData((prevFormData) => {
      const newKeyPoints = prevFormData.bookIncludes.filter(
        (_, i) => i !== index
      );
      return { ...prevFormData, bookIncludes: newKeyPoints };
    });
  };

  // Add additional field
  const addBookIncludes = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      bookIncludes: [...prevFormData.bookIncludes, { name: "", value: "" }],
    }));
  };

  const handleSelectChangeProduct = ({
    value,
    name,
  }: {
    value: string;
    name: string;
  }) => {
    if (name === "product") {
      const product = products?.find((product) => product.productId === value);

      if (product) {
        const updatedProductData = {
          productId: product.productId,
          productSlug: product.slug,
          productTitle: product.name,
          bookImage: product.bookImage,
          bookName: product.name,
          main_price: product.main_price,
          stroked_price: product.stroked_price,
        };
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...updatedProductData,
        }));
        form.setFieldsValue(updatedProductData);

        if (product.global_category) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            category: product.global_category,
          }));
          form.setFieldsValue({ category: product.global_category });
        }
      }
    } else if (name === "author") {
      const author = authors?.find((author) => author.authorId === value);
      if (author) {
        const updatedAuthorData = {
          authorDesignation: author.designation as string,
          authorId: author.authorId,
          authorImage: author.avatar,
          authorName: author.name,
          authorZlUid: author.zl_uid,
          authUser_name: author.user_name,
        };
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...updatedAuthorData,
        }));
        form.setFieldsValue(updatedAuthorData);
      }
    }
  };

  return (
    <>
      <ShouldRender check={!loading}>
        <Affix offsetTop={0}>
          <div className="bg-[#1E1E1F] px-5 py-3 flex justify-between items-center">
            <div className="flex items-center gap-x-4">
              <span className=" font-semibold">
                <Button
                  size="small"
                  onClick={() => router("/book-landing-page")}
                  type="text"
                  className="flex items-center"
                  icon={<ChevronLeftIcon className="w-3.5" />}
                >
                  Back
                </Button>
              </span>

              <span className=" font-semibold">
                Course landing page details
              </span>
            </div>

            <span className="flex gap-x-4">
              {" "}
              <ButtonDefault
                size={2}
                variant={ButtonVariants.GRAY}
                className="p-1 "
                // disabled={confirmLoading}
                // loading={confirmLoading}
                rounded={true}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <span className="flex text-sm  font-bold gap-x-2 px-4  whitespace-nowrap">
                  Save
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={2}
                variant={ButtonVariants.GRAY}
                className="p-1 "
                // disabled={confirmLoading}
                // loading={confirmLoading}
                rounded={true}
                onClick={() => {
                  setIsPublish(true);
                  handleSubmit(true);
                }}
              >
                <span className="flex text-sm  font-bold gap-x-2 px-4  whitespace-nowrap">
                  Publish
                </span>
              </ButtonDefault>
            </span>
          </div>
        </Affix>
        <div className="pt-4 px-4 md:px-10 pb-10">
          <Form
            form={form}
            layout="horizontal"
            className="flex gap-y-2 flex-col"
            initialValues={formData}
            labelCol={{ span: screens.xl ? 4 : screens.lg ? 6 : 8 }}
            wrapperCol={{ offset: 1 }}
            labelAlign="left"
            colon={false}
            requiredMark={false}
            scrollToFirstError
          >
            <Divider orientation="center">Courses Hero Section</Divider>
            <Form.Item
              label={<span className="font-medium">Course</span>}
              name="productTitle"
              valuePropName="productTitle"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the product title",
                },
              ]}
            >
              <Select
                showSearch
                style={{ height: 40 }}
                value={formData?.productId || undefined}
                placeholder="Select Course Type"
                filterOption={(input, option) =>
                  (option?.name ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.name ?? "")
                    ?.toLowerCase()
                    .localeCompare((optionB?.name ?? "")?.toLowerCase())
                }
                onChange={(value) =>
                  handleSelectChangeProduct({
                    value,
                    name: "product",
                  })
                }
                // disabled={}
                className="rounded-xl border border-bgDarkGray  antdSelect "
                options={products?.map((product) => ({
                  value: product?.productId,
                  label: product?.name,
                  name: product?.name?.toLowerCase(),
                }))}
              />
            </Form.Item>
            <Form.Item
              label={<span className="font-medium">Course Name</span>}
              name="bookName"
              valuePropName="bookName"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the book name",
                },
              ]}
            >
              <Input
                placeholder="Course Name"
                size="large"
                value={formData.bookName}
                onChange={(e) => handleInputChange("bookName", e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label={
                <div className="flex font-medium flex-col">
                  <span className="font-medium">Tags</span>{" "}
                </div>
              }
              name="tags"
              valuePropName="tags"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the tags",
                },
                {
                  validator: () => {
                    if (
                      !formData?.tags ||
                      (formData?.tags?.length === 1 &&
                        formData?.tags[0]?.trim() === "")
                    ) {
                      return Promise.reject(new Error("Tags cannot be empty"));
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData?.tags?.map((tag, index) => (
                <div key={index} className="flex mb-2">
                  <Input
                    className="border-gray-800"
                    size="large"
                    placeholder={`Tags ${index + 1}`}
                    value={tag}
                    onChange={(e) => {
                      const newTag = formData.tags?.length
                        ? [...formData.tags]
                        : [];
                      newTag[index] = e.target.value;
                      handleInputChange("tags", newTag);
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      const newTag = formData.tags?.length
                        ? [...formData.tags]
                        : [];
                      newTag.splice(index, 1);
                      handleInputChange("tags", newTag);
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                disabled={(formData?.tags?.length ?? [].length) >= 6}
                onClick={() => {
                  handleInputChange("tags", [...(formData.tags ?? []), ""]);
                }}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add More
              </Button>
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Course Slug</span>}
              name="productSlug"
              valuePropName="productSlug"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the product slug",
                },
              ]}
            >
              <Input
                placeholder="{product slug}"
                disabled={true}
                className="  border-gray-800"
                prefix="https://zebralearn.com/books/"
                size="large"
                value={formData.productSlug}
                onChange={(e) =>
                  handleInputChange("productSlug", e.target.value)
                }
              />
              <span className="text-[#737373]">
                example: https://zebralearn.com/books/
                <b>financial-statement-analytics</b>
              </span>
            </Form.Item>
            <Form.Item
              label={<span className="font-medium">Course Level</span>}
              name="courseLevel"
              rules={[
                {
                  required: isPublish,
                  message: "Please select the course level",
                },
              ]}
            >
              <Select
                placeholder="Select course level"
                size="large"
                className="border-gray-800"
                value={formData.courseLevel}
                onChange={(value) => handleInputChange("courseLevel", value)}
              >
                <Select.Option value="beginner">Beginner</Select.Option>
                <Select.Option value="intermediate">Intermediate</Select.Option>
                <Select.Option value="advanced">Advanced</Select.Option>
                <Select.Option value="expert">Expert</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span className="font-medium">Course Short Description</span>
              }
              name="shortDescription"
              valuePropName="shortDescription"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the product short description",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter short description"
                className="  border-gray-800"
                size="large"
                value={formData.shortDescription}
                onChange={(e) =>
                  handleInputChange("shortDescription", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Course Duration</span>}
              name="courseDuration"
              valuePropName="courseDuration"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the course duration",
                },
              ]}
            >
              <Input
                placeholder="Enter course duration ex: 6hr 31 min"
                className="  border-gray-800"
                size="large"
                value={formData.courseDuration}
                onChange={(e) =>
                  handleInputChange("courseDuration", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Course Image</span>}
              name="bookImage"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the book image",
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  This picture will be visible to everyone
                </span>
                <span className="w-1/2">
                  <ImageUploadUrl
                    uploadAction="Enter book Image"
                    maxFileCount={1}
                    // requiredWidth={308}
                    // requiredHeight={154}
                    aspectRatio={308 / 154}
                    updateText={"Drag and drop files here or click to upload"}
                    onChange={(value) => handleInputChange("bookImage", value)}
                    defaultImage={
                      typeof formData.bookImage === "string"
                        ? formData.bookImage
                        : ""
                    }
                    clearImage={true}
                  />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Course Sample Link</span>}
              name="courseSampleLink"
              valuePropName="courseSampleLink"
              rules={[
                {
                  required: isPublish,
                  message: "course sample Link URL is required.",
                },
                {
                  validator: () => {
                    // Check if the URL is empty or invalid
                    if (
                      !formData.courseSampleLink ||
                      formData.courseSampleLink.trim() === ""
                    ) {
                      return Promise.reject(
                        new Error("Course Sample Link URL cannot be empty.")
                      );
                    }
                    // Simple regex pattern to check if the input is a valid URL
                    const urlPattern = new RegExp(
                      "^(https?:\\/\\/)?" + // protocol
                        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
                        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                        "(\\#[-a-z\\d_]*)?$",
                      "i"
                    );

                    if (!urlPattern.test(formData.courseSampleLink)) {
                      return Promise.reject(
                        new Error("Please enter a valid URL.")
                      );
                    }

                    // If everything is valid, resolve the promise
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Enter course sample link"
                className="border-gray-800"
                value={formData.courseSampleLink}
                size="large"
                onChange={(e) => {
                  handleInputChange("courseSampleLink", e.target.value);
                }}
              />
            </Form.Item>

            <Divider orientation="center">Author Details</Divider>

            <Form.Item
              label={<span className="font-medium">Author Name</span>}
              name="authorId"
              valuePropName="authorId"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the author name",
                },
              ]}
            >
              <Select
                showSearch
                style={{ height: 40 }}
                placeholder="Select Author Name"
                filterOption={(input, option) =>
                  (option?.name ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.name ?? "")
                    ?.toLowerCase()
                    .localeCompare((optionB?.name ?? "")?.toLowerCase())
                }
                value={formData?.authorId || undefined}
                onChange={(value) => {
                  handleSelectChangeProduct({
                    value,
                    name: "author",
                  });
                }}
                className="rounded-xl bg-readerLightGrayBg placeholder:font-medium border border-bgDarkGray"
                options={authors?.map((product) => ({
                  value: product?.authorId,
                  label: product?.name,
                  name: product?.name.toLowerCase(),
                  authorZlUid: product?.zl_uid,
                  authUser_name: product?.user_name,
                }))}
              />
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Author Image</span>}
              name="authorImage"
              valuePropName="authorImage"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the author image",
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  This picture will be visible to all. Aspect Ratio (136 / 191)
                </span>
                <span className="w-1/2">
                  <ImageUploadUrl
                    uploadAction="Enter author image"
                    maxFileCount={1}
                    aspectRatio={136 / 191}
                    updateText={"Drag and drop files here or click to upload"}
                    onChange={(value) => {
                      handleInputChange("authorImage", value);
                    }}
                    defaultImage={
                      typeof formData.authorImage === "string"
                        ? formData.authorImage
                        : ""
                    }
                    clearImage={true}
                  />
                </span>
              </div>
            </Form.Item>

            <Form.Item
              label={
                <div className="flex font-medium flex-col">
                  <span className="font-medium">Author Description </span>{" "}
                  <span> (Max 6)</span>
                </div>
              }
              name="authorDescription"
              valuePropName="authorDescription"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the author description",
                },
                {
                  validator: () => {
                    if (
                      !formData?.authorDescription ||
                      (formData?.authorDescription?.length === 1 &&
                        formData?.authorDescription[0]?.trim() === "")
                    ) {
                      return Promise.reject(
                        new Error("Author Description cannot be empty")
                      );
                    }
                    if (formData?.authorDescription?.length > 6) {
                      return Promise.reject(
                        new Error("You can only add up to 6 descriptions")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData?.authorDescription?.map((desc, index) => (
                <div key={index} className="flex mb-2">
                  <Input.TextArea
                    className="border-gray-800"
                    size="large"
                    placeholder={`Description ${index + 1}`}
                    value={desc}
                    onChange={(e) => {
                      const newDescriptions = [...formData.authorDescription];
                      newDescriptions[index] = e.target.value;
                      handleInputChange("authorDescription", newDescriptions);
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      const newDescriptions = [...formData.authorDescription];
                      newDescriptions.splice(index, 1);
                      handleInputChange("authorDescription", newDescriptions);
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                disabled={formData?.authorDescription?.length >= 6}
                onClick={() => {
                  handleInputChange("authorDescription", [
                    ...formData.authorDescription,
                    "",
                  ]);
                }}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add More
              </Button>
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Author Designation</span>}
              name="authorDesignation"
              valuePropName="authorDesignation"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the author designation",
                },
              ]}
            >
              <Input
                placeholder="Enter author designation"
                className="  border-gray-800"
                size="large"
                value={formData.authorDesignation}
                onChange={(e) =>
                  handleInputChange("authorDesignation", e.target.value)
                }
              />
            </Form.Item>

            <Form.Item
              label={<span className="font-medium">Company Icon</span>}
              name="companyIcon"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the company icon",
                },
              ]}
            >
              <div className="flex gap-x-4">
                <span className="w-1/2 text-textGray">
                  Add Author’s company/rewards image. Aspect Ratio: (1 / 1)
                </span>
                <span className="w-1/2">
                  <ImageUploadUrl
                    uploadAction="Enter company icon"
                    maxFileCount={1}
                    updateText={"Drag and drop files here or click to upload"}
                    onChange={(value) =>
                      handleInputChange("companyIcon", value)
                    }
                    defaultImage={
                      typeof formData.companyIcon === "string"
                        ? formData.companyIcon
                        : ""
                    }
                    clearImage={true}
                  />
                </span>
              </div>
            </Form.Item>

            <Divider orientation="center">Extras</Divider>
            <Form.Item
              label={
                <div className="flex font-medium flex-col">
                  <span className="font-medium">What You Will Learn</span>{" "}
                </div>
              }
              name="whatYouWillLearn"
              valuePropName="whatYouWillLearn"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter what you will learn points",
                },
                {
                  validator: () => {
                    if (
                      !formData?.whatYouWillLearn ||
                      (formData?.whatYouWillLearn?.length === 1 &&
                        formData?.authorDescription[0]?.trim() === "")
                    ) {
                      return Promise.reject(
                        new Error("will learn points cannot be empty")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData?.whatYouWillLearn?.map((point, index) => (
                <div key={index} className="flex mb-2">
                  <Input.TextArea
                    className="border-gray-800"
                    size="large"
                    placeholder={`Points ${index + 1}`}
                    value={point}
                    onChange={(e) => {
                      const newDescriptions = [...formData.whatYouWillLearn];
                      newDescriptions[index] = e.target.value;
                      handleInputChange("whatYouWillLearn", newDescriptions);
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      const newDescriptions = [...formData.whatYouWillLearn];
                      newDescriptions.splice(index, 1);
                      handleInputChange("whatYouWillLearn", newDescriptions);
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                onClick={() => {
                  handleInputChange("whatYouWillLearn", [
                    ...formData.whatYouWillLearn,
                    "",
                  ]);
                }}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add More
              </Button>
            </Form.Item>

            <Form.Item
              label={
                <div className="flex font-medium flex-col">
                  <span className="font-medium">Skills</span>{" "}
                </div>
              }
              name="skills"
              valuePropName="skills"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the skills",
                },
                {
                  validator: () => {
                    if (
                      !formData?.skills ||
                      (formData?.skills?.length === 1 &&
                        formData?.skills[0]?.trim() === "")
                    ) {
                      return Promise.reject(
                        new Error("Skills cannot be empty")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData?.skills?.map((skill, index) => (
                <div key={index} className="flex mb-2">
                  <Input
                    className="border-gray-800"
                    size="large"
                    placeholder={`Skills ${index + 1}`}
                    value={skill}
                    onChange={(e) => {
                      const newSkill = formData.skills?.length
                        ? [...formData.skills]
                        : [];
                      newSkill[index] = e.target.value;
                      handleInputChange("skills", newSkill);
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      const newSkill = formData.skills?.length
                        ? [...formData.skills]
                        : [];
                      newSkill.splice(index, 1);
                      handleInputChange("skills", newSkill);
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                onClick={() => {
                  handleInputChange("skills", [...(formData.skills ?? []), ""]);
                }}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add More Skills
              </Button>
            </Form.Item>
            <Form.Item
              label={<span className="font-medium">Course Includes</span>}
              name="bookIncludes"
              valuePropName="bookIncludes"
              rules={[
                {
                  required: isPublish,
                  message: "Please add at least one book Includes",
                },
                {
                  validator: () => {
                    if (
                      !formData?.bookIncludes ||
                      formData?.bookIncludes?.length === 0 ||
                      formData.bookIncludes.some((field) => !field.name.trim())
                    ) {
                      return Promise.reject(
                        new Error("Field name cannot be empty")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData.bookIncludes.map((field, fieldIndex) => (
                <div key={fieldIndex} className="mb-5">
                  <span className="flex justify-between">
                    <div className="text-primary underline">
                      Field {fieldIndex + 1}
                    </div>
                    <Button
                      type="link"
                      className="flex gap-2 items-center"
                      onClick={() => removeBookIncludes(fieldIndex)}
                    >
                      <MinusOutlined className="w-4" /> Remove Field
                    </Button>
                  </span>
                  <Form.Item
                    label={<span className="font-medium">Field Name</span>}
                    name={`bookIncludes[${fieldIndex}].name`}
                    valuePropName={`bookIncludes[${fieldIndex}].name`}
                  >
                    <Select
                      placeholder="Select field name"
                      className="border-gray-800"
                      size="large"
                      value={field.name === "" ? undefined : field.name}
                      onChange={(value) =>
                        handleBookIncludesChange(fieldIndex, "name", value)
                      }
                      options={[
                        {
                          label: "On-Demand video (number of hours)",
                          value: "onDemandVideo",
                        },
                        {
                          label: "Exercises (number)",
                          value: "exercises",
                        },
                        {
                          label: "Article (number of article)",
                          value: "articles",
                        },
                        {
                          label: "Downloadable Resources (number of resources)",
                          value: "downloadableResources",
                        },
                        {
                          label: "Full Lifetime Access",
                          value: "fullLifetimeAccess",
                        },
                        {
                          label: "Certificate of completion",
                          value: "certificate",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span className="font-medium">Field Value</span>}
                    name={`bookIncludes[${fieldIndex}].value`}
                    valuePropName={`bookIncludes[${fieldIndex}].value`}
                  >
                    <Input
                      placeholder="Enter field value (optional)"
                      className="border-gray-800"
                      size="large"
                      value={field.value}
                      onChange={(e) =>
                        handleBookIncludesChange(
                          fieldIndex,
                          "value",
                          e.target.value
                        )
                      }
                    />
                  </Form.Item>
                </div>
              ))}
              <Button
                type="dashed"
                onClick={addBookIncludes}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add Field
              </Button>
            </Form.Item>

            <Divider />

            <Form.Item
              label={<span className="font-medium">Course Content</span>}
              name="modules"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter module details",
                },
                {
                  validator: () => {
                    if (!formData.modules?.length) {
                      return Promise.reject(
                        new Error("At least one module is required")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData.modules?.map((module, moduleIndex) => (
                <div key={moduleIndex} className="mb-5">
                  <span className="flex justify-between">
                    <div className="text-primary underline">
                      Module {moduleIndex + 1}
                    </div>
                    <Button
                      type="link"
                      className="flex gap-2 items-center"
                      onClick={() => removeModule(moduleIndex)}
                    >
                      <MinusOutlined className="w-4" /> Remove Module
                    </Button>
                  </span>

                  {/* Module Name */}
                  <Form.Item
                    label="Module Name"
                    name={["modules", moduleIndex, "name"]}
                  >
                    <Input
                      placeholder="Enter module name"
                      size="large"
                      onChange={(e) =>
                        handleModuleChange(moduleIndex, "name", e.target.value)
                      }
                    />
                  </Form.Item>
                  {/* Module Lesson Number */}
                  <Form.Item
                    label="Module Lesson Number"
                    name={["modules", moduleIndex, "lesson"]}
                  >
                    <InputNumber
                      placeholder="Enter lesson number"
                      size="large"
                      className="w-full"
                      onChange={(value) =>
                        handleModuleChange(moduleIndex, "lesson", value)
                      }
                    />
                  </Form.Item>

                  {/* Module Duration */}
                  <Form.Item
                    label="Module Duration"
                    name={["modules", moduleIndex, "duration"]}
                  >
                    <Input
                      placeholder="Enter module duration (e.g., 1hr 40min)"
                      size="large"
                      onChange={(e) =>
                        handleModuleChange(
                          moduleIndex,
                          "duration",
                          e.target.value
                        )
                      }
                    />
                  </Form.Item>

                  {/* Sections */}
                  {/* Sections */}
                  <Form.Item
                    label="Module Sections"
                    name={["modules", moduleIndex, "sections"]}
                  >
                    {module.sections.map((_, sectionIndex) => (
                      <div key={sectionIndex} className="flex gap-2 mb-2">
                        {/* Section Name */}
                        <Form.Item
                          name={[
                            "modules",
                            moduleIndex,
                            "sections",
                            sectionIndex,
                            "name",
                          ]}
                        >
                          <Input
                            placeholder={`Section ${sectionIndex + 1} Name`}
                            size="large"
                            onChange={(e) =>
                              handleSectionChange(
                                moduleIndex,
                                sectionIndex,
                                "name",
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>

                        {/* Section Duration */}
                        <Form.Item
                          name={[
                            "modules",
                            moduleIndex,
                            "sections",
                            sectionIndex,
                            "duration",
                          ]}
                        >
                          <Input
                            placeholder={`Section ${sectionIndex + 1} Duration`}
                            size="large"
                            onChange={(e) =>
                              handleSectionChange(
                                moduleIndex,
                                sectionIndex,
                                "duration",
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>

                        {/* Section Video Link */}
                        <Form.Item
                          name={[
                            "modules",
                            moduleIndex,
                            "sections",
                            sectionIndex,
                            "videoLink",
                          ]}
                        >
                          <Input
                            placeholder={`Section ${
                              sectionIndex + 1
                            } Video Link`}
                            size="large"
                            onChange={(e) =>
                              handleSectionChange(
                                moduleIndex,
                                sectionIndex,
                                "videoLink",
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>

                        {/* Remove Section Button */}
                        <Button
                          type="link"
                          onClick={() =>
                            removeSection(moduleIndex, sectionIndex)
                          }
                        >
                          <MinusOutlined />
                        </Button>
                      </div>
                    ))}

                    {/* Add Section Button */}
                    <Button
                      type="dashed"
                      onClick={() => addSection(moduleIndex)}
                      className="flex gap-x-2 items-center mb-4"
                    >
                      <PlusOutlined /> Add Section
                    </Button>
                  </Form.Item>
                </div>
              ))}
              <Button
                type="dashed"
                onClick={addModule}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add Module
              </Button>
            </Form.Item>
            <Form.Item
              label={
                <div className="flex font-medium flex-col">
                  <span className="font-medium">Requirements</span>{" "}
                </div>
              }
              name="requirements"
              valuePropName="requirements"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the requirements",
                },
                {
                  validator: () => {
                    if (
                      !formData?.requirements ||
                      (formData?.requirements?.length === 1 &&
                        formData?.requirements[0]?.trim() === "")
                    ) {
                      return Promise.reject(
                        new Error("Requirements cannot be empty")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              {formData?.requirements?.map((requirement, index) => (
                <div key={index} className="flex mb-2">
                  <Input
                    className="border-gray-800"
                    size="large"
                    placeholder={`Requirements ${index + 1}`}
                    value={requirement}
                    onChange={(e) => {
                      const newRequirements = formData.requirements?.length
                        ? [...formData.requirements]
                        : [];
                      newRequirements[index] = e.target.value;
                      handleInputChange("requirements", newRequirements);
                    }}
                  />
                  <Button
                    type="link"
                    onClick={() => {
                      const newRequirement = formData.requirements?.length
                        ? [...formData.requirements]
                        : [];
                      newRequirement.splice(index, 1);
                      handleInputChange("requirements", newRequirement);
                    }}
                  >
                    <MinusOutlined />
                  </Button>
                </div>
              ))}
              <Button
                type="dashed"
                disabled={(formData?.requirements?.length ?? [].length) >= 6}
                onClick={() => {
                  handleInputChange("requirements", [
                    ...(formData.requirements ?? []),
                    "",
                  ]);
                }}
                className="flex gap-x-2 items-center mb-4"
              >
                <PlusOutlined /> Add More
              </Button>
            </Form.Item>
            <Form.Item
              label={<span className="font-medium">Review Overview (AI)</span>}
              name="reviewOverview"
              valuePropName="reviewOverview"
              rules={[
                {
                  required: isPublish,
                  message: "Please enter the reviewOverview",
                },
              ]}
            >
              <Input
                placeholder="Review Overview"
                size="large"
                value={formData.reviewOverview}
                onChange={(e) =>
                  handleInputChange("reviewOverview", e.target.value)
                }
              />
            </Form.Item>
          </Form>
        </div>
      </ShouldRender>
      <ShouldRender check={loading}>
        <CustomLoader />
      </ShouldRender>
    </>
  );
};

export default CreateCourseLandingPage;
