import { Modal, Select } from "antd";
import React, { useEffect, useState } from "react";

import ButtonDefault, { ButtonVariants } from "../../shared/basic/button";
import { notify } from "../../shared/basic/notify";
import { formateErrorObject } from "../../shared/formateErrorObject";

import extractErrorMessage from "../../shared/basic/formateError";
import {
  createBannerPage,
  editBannerPage,
  getAllBanner,
} from "../../../services/banner";
import { ShouldRender } from "../../shared/basic/ShouldRender";

interface ModalComponentProps {
  visible: boolean;
  onCancel: () => void;
  isClosable?: boolean;
  type?: string;
  page?: string;
}

export const generateUrlForImageBanner = (
  baseUrl: string,
  page: string,
  type: string
): string => {
  const url = `${baseUrl}api/banner/${page}image/${type}`;
  return url;
};

export enum PageNames {
  HOME = "home",
  SCHOOL = "school",
  BOOKS = "books",
  LIVE = "live",
  COURSES = "course",
}

export const PageArray = [
  {
    label: "Home",
    value: "home",
  },
  {
    label: "Live",
    value: "live",
  },
  {
    label: "School",
    value: "school",
  },
  {
    label: "Books",
    value: "books",
  },
  {
    label: "Courses",
    value: "course",
  },
];
const CreateBannerPage: React.FC<ModalComponentProps> = ({
  visible,
  onCancel,
  type,
  page,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [formData, setFormData] = useState<{
    page: PageNames | "";
  }>({
    page: "",
  });

  const [errors, setErrors] = useState({
    page: "",
  });

  const handleCancel = () => {
    onCancel();
    setErrors({
      page: "",
    });
    setFormData({
      page: "",
    });
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setConfirmLoading(false);
      handleCancel();
    }, 2000);
  };

  const handleSelectChange = ({
    value,
    name,
  }: {
    value: string;
    name: string;
  }) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validationErrors = {
      page: "",
    };
    if (!formData?.page && type !== "Edit") {
      validationErrors.page = "Please Enter Page";
    }

    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => !error)) {
      try {
        setConfirmLoading(true);
        if (type !== "Edit") {
          await createBannerPage({
            page: formData?.page as PageNames,
          });
          notify("Banner Page Created Successfully", "success");
        } else {
          await editBannerPage(formData.page?.toString() as string, {
            page: formData?.page as PageNames,
          });
          notify("Banner Page updated Successfully", "success");
        }

        handleCancel();
      } catch (error: any) {
        const message = formateErrorObject(error);
        notify(message, "error");
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const handleSingleBanner = async (page: string) => {
    try {
      //   setLoading(true);
      const response = await getAllBanner(page);
      const data = response?.data;
      setFormData({
        page: data?.page,
      });
    } catch (error: any) {
      const message = extractErrorMessage(error);
      notify(message, "error");
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    if (page) {
      handleSingleBanner(page.toString());
    }
  }, [page]);

  return (
    <Modal
      title=""
      open={visible}
      centered={true}
      onOk={handleOk}
      closable={false}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      footer={false}
      className="customLoginModal bg-bgColor bg-opacity-70  pt-5  relative rounded-2xl border-2 border-borderDarkGray backdrop-filter backdrop-blur-md "
    >
      <div className="flex items-center flex-col justify-center px-5 md:px-10">
        <div className={` z-30  flex flex-col gap-y-1 w-full `}>
          <div className="pb-3 flex flex-col gap-y-2 ">
            <h1 className="text-xl flex justify-center gap-x-2 font-medium lg:font-normal lg:text-2xl mt-1 leading-10 text-fadedWhite text-center w-full">
              {type} Banner Page
            </h1>
          </div>

          <form className="flex flex-col mt-2 gap-y-3 w-full ">
            <ShouldRender check={type !== "Edit"}>
              <div className="flex max-w-full gap-x-2">
                <div className="col-span-1 flex-col flex gap-y-.5 w-full">
                  <Select
                    style={{ height: 40 }}
                    value={formData.page || undefined}
                    placeholder="Select Page"
                    onChange={(value) =>
                      handleSelectChange({
                        value,
                        name: "page",
                      })
                    }
                    className="rounded-xl border border-bgDarkGray  antdSelect "
                    options={PageArray}
                  />
                  {errors.page && (
                    <p className="text-red-500 text-xs">{errors.page}</p>
                  )}
                </div>
              </div>
            </ShouldRender>

            <span className="flex justify-center gap-x-2 mt-2 pb-4">
              <ButtonDefault
                size={4}
                variant={ButtonVariants.GRAY}
                className="p-1 w-1/3"
                // disabled={confirmLoading}
                // loading={confirmLoading}
                rounded={true}
                onClick={() => handleCancel()}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  Cancel
                </span>
              </ButtonDefault>
              <ButtonDefault
                size={4}
                variant={ButtonVariants.WHITE}
                className="p-1 w-2/3"
                disabled={confirmLoading}
                loading={confirmLoading}
                rounded={true}
                onClick={handleSubmit}
              >
                <span className="flex text-sm font-bold gap-x-2 px-4 py-1 whitespace-nowrap">
                  {type} Banner Page
                </span>
              </ButtonDefault>
            </span>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default CreateBannerPage;
